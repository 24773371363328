import React from 'react'

export const NoResultsComponent: React.FC = () => {
  return (
    <div className="noresult">
      <div className="text-center">
        <h5 className="mt-2">Sorry! No Data Found</h5>
        {/* <p className="text-muted">We have searched more than 150+ Orders We did not find any orders for you search.</p> */}
      </div>
    </div>
  )
}
