import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IProduct } from '../interface/product.interface';
import {
	listAdminProductsAction,
	listSellerProductsAction,
	updateSellerProductAction
} from '../actions/product.actions';
import { ErrorResponse } from '../../../utility/interfaces/ErrorResponse.interface';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';

export const SuccessAlert = withReactContent(Swal);

interface DataReducer {
	products: IProduct[];
	status: string;
	error: ErrorResponse | null;
}

const initialState: DataReducer = {
	products: [],
	status: 'idle',
	error: null
};

const productSlice = createSlice({
	name: 'product',
	initialState,
	reducers: {
		setStatus(state: DataReducer, action: PayloadAction<any>) {
			state.status = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(listSellerProductsAction.pending, (state) => {
				state.status = REDUX_STATUS.PENDING;
			})
			.addCase(listSellerProductsAction.fulfilled, (state, action) => {
				state.status = REDUX_STATUS.SUCCEEDED;
				state.products = action.payload.data;
			})
			.addCase(listSellerProductsAction.rejected, (state, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
				state.status = REDUX_STATUS.FAILED;
			})

			.addCase(listAdminProductsAction.pending, (state) => {
				state.status = REDUX_STATUS.PENDING;
			})
			.addCase(listAdminProductsAction.fulfilled, (state, action) => {
				state.status = REDUX_STATUS.SUCCEEDED;
				state.products = action.payload.data;
			})
			.addCase(listAdminProductsAction.rejected, (state, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
				state.status = REDUX_STATUS.FAILED;
			})

			.addCase(updateSellerProductAction.pending, (state) => {
				state.status = REDUX_STATUS.PENDING;
			})
			.addCase(updateSellerProductAction.fulfilled, (state, action) => {
				state.status = REDUX_STATUS.SUCCEEDED;
				const updatedState = state.products?.map((product: IProduct) => {
					if (product._id == action.payload.data._id) {
						return action.payload.data._id;
					} else {
						return product;
					}
				});
				state.products = updatedState!;
				SuccessAlert.fire({ position: 'center', icon: 'success', title: 'Product Updated successfully', showConfirmButton: !0, timer: 5000 });
			})
			.addCase(updateSellerProductAction.rejected, (state, action: any) => {
				state.status = REDUX_STATUS.FAILED;
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 });
			});
	}
});

export const productActions = productSlice.actions;
export default productSlice.reducer;
