import React from 'react';
import { Navigate } from 'react-router-dom';
import { useProfile } from '../provider/Profile.provider';
import { ROLE } from '../utility/enums/role.enum';

const RoleGuard = (props: { children: any; }) => {
	
	const userProfile = useProfile()

	if (userProfile.role?.type != ROLE.ADMIN) {
		return <Navigate to="/" replace />;
	} else {
		return props.children;
	}
};

export default RoleGuard;
