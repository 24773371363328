import React, { ChangeEvent, useEffect, useState } from 'react';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface';
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component';
import WarningComponent from '../../../components/Confirmation-modals/warning.component';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { deleteSellerProductAction, listAdminProductsAction, listSellerProductsAction } from '../actions/product.actions';
import { ROLE } from '../../../utility/enums/role.enum';
import { useProfile } from '../../../provider/Profile.provider';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum';
import { Translation } from '../../../utility/helpers/translation.helper';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';
import { IProduct } from '../interface/product.interface';
import { useLoading } from '../../../provider/IsLoading.provider';
import { NoResultsComponent } from '../../../components/no-results/NoResults.component';

const ProductsListPage: React.FC<any> = () => {
	const assets = process.env.REACT_APP_ASSETS_URL;
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { products, status } = useAppSelector((state) => state.product);
	const userProfile = useProfile();
	const { setLoading } = useLoading();
	const [deleteId, setDeleteId] = useState('');
	const breadcrumb: Breadcrumb = {
		title: t('Products'),
		links: [
			{ name: t('Dashboard'), path: '/' },
			{ name: t('Products'), path: '/products/list' }
		]
	};

	useEffect(() => {
		if (status == REDUX_STATUS.SUCCEEDED || status == REDUX_STATUS.FAILED) {
			setLoading(false);
		}
	}, [setLoading, status]);

	useEffect(() => {
		if (status == REDUX_STATUS.IDLE) {
			setLoading(true);
			if (userProfile.role?.type == ROLE.ADMIN) {
				dispatch(
					listAdminProductsAction({
						page: 1,
						perPage: 12,
						orderBy: 'createdAt',
						orderDirection: SORT_DIRECTION.ASC
					})
				);
			} else {
				dispatch(
					listSellerProductsAction({
						page: 1,
						perPage: 12,
						orderBy: 'createdAt',
						orderDirection: SORT_DIRECTION.ASC
					})
				);
			}
		}
	}, [dispatch, userProfile, status, setLoading]);

	const deleteHandler = () => {
		dispatch(deleteSellerProductAction(deleteId!));
	};

	const productSerachHandler = (e: ChangeEvent<HTMLInputElement>) => {
		if(e.target?.value.length > 2){
			if (userProfile.role?.type == ROLE.ADMIN) {
				dispatch(
					listAdminProductsAction({
						page: 1,
						perPage: 12,
						orderBy: 'createdAt',
						orderDirection: SORT_DIRECTION.ASC,
						search: e.target?.value
					})
				);
			} else {
				dispatch(
					listSellerProductsAction({
						page: 1,
						perPage: 12,
						orderBy: 'createdAt',
						orderDirection: SORT_DIRECTION.ASC,
						search: e.target?.value
					})
				);
			}
		}
	}

	return (
		<div className="container-fluid">
			<div className="row">
				<BreadcrumbComponent breadcrumb={breadcrumb} />
			</div>

			<div className="row">
				<div className="col-md-12">
					<div className="card">
						<div className="card-header border-0">
							<div className="row g-4">
								{userProfile.role?.type === ROLE.SELLER &&
									<div className="col-sm-auto">
										<div>
											<Link to={'/products/create'} className="btn btn-primary" id="addproduct-btn">
												<i className="ri-add-line align-bottom me-1"></i> {t("AddNew")}
											</Link>
										</div>
									</div>
								}
								<div className="col-sm">
									<div className="d-flex justify-content-sm-end">
										<div className="search-box ms-2">
											<input type="text" className="form-control bg-light border-0" placeholder={t("Search_Placeholder")} onChange={(e) => productSerachHandler(e)} />
											<i className="ri-search-line search-icon"></i>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="card-body">
							{products.length > 0 ?
								<div id="table-product-list-all" className="table-card">
									<div className="table-responsive">
										<table className="table align-middle table-nowrap mb-0">
											<thead className="table-light">
												<tr>
													<th scope="col">{t("Name")}</th>
													<th scope="col">{t('Category')}</th>
													<th scope="col">{t('DelivaryType')}</th>
													<th scope="col">{t('IsActive')}</th>
													<th scope="col">{t('Rating')}</th>
													<th scope="col">{t('Actions')}</th>
												</tr>
											</thead>
											<tbody>
												{products.map((product: IProduct) =>

													<tr key={product._id}>
														<td>
															<div className="d-flex align-items-center">
																<div className="flex-shrink-0 mx-2">
																	<div className="avatar-sm bg-light rounded p-1">
																		<img src={assets + product.mainImage} alt={product.name?.En} className="img-fluid d-block" />
																	</div>
																</div>
																<div className="flex-grow-1">
																	<h5 className="fs-14 mb-1">
																		<Link to={'/products/view/' + product._id} className="text-body">
																			{Translation(product.name)}
																		</Link>
																	</h5>
																	<p className="text-muted mb-0">
																		{t('Category')} : <span className="fw-medium">{Translation(product.category?.name)}</span>
																	</p>
																</div>
															</div>
														</td>
														<td>{Translation(product.category?.name)}</td>
														<td>{t(product.delivaryType)}</td>
														<td>{product.isActive ? (
															<span className="badge bg-success-subtle text-success text-uppercase">{t('Active')}</span>
														) : (
															<span className="badge bg-danger-subtle text-danger text-uppercase">{t('InActive')}</span>
														)}
														</td>
														<td><span className="badge bg-light text-body fs-12 fw-medium"><i className="mdi mdi-star text-warning me-1"></i> ${product.rating} </span></td>
														<td>
															<ul className="list-inline hstack gap-2 mb-0">
																<li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title={t("View")}>
																	<Link to={'/products/view/' + product._id} className="text-body">
																		<i className="ri-eye-fill fs-16"></i>
																	</Link>
																</li>
																<li className="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title={t("Edit")}>
																	<Link to={'/products/edit/' + product._id} className="text-primary d-inline-block edit-item-btn" role="button">
																		<i className="ri-pencil-fill fs-16"></i>
																	</Link>
																</li>
																<li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title={t("Remove")}>
																	<a
																		role="button"
																		className="text-danger d-inline-block remove-item-btn"
																		data-bs-toggle="modal"
																		data-bs-target="#warningModal"
																		onClick={() => setDeleteId(product._id)}>
																		<i className="ri-delete-bin-5-fill fs-16"></i>
																	</a>
																</li>
															</ul>
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
								</div> : <NoResultsComponent />
							}
						</div>
					</div>
				</div>
			</div>
			<WarningComponent title={t("Confirm")} text={t("Confirm_Delete_Product")} actionHandler={deleteHandler} />
		</div>
	);
};

export default ProductsListPage;
