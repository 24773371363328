import React, { useEffect, useState } from 'react';
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { getAttributesAction } from '../../attribute/actions/attribute.actions';
import { DELIVARY_TYPE } from '../../../utility/enums/delivary-type.enum';
import { DISCOUNT_TYPE } from '../../../utility/enums/discount-type.enum';
import { ICategory } from '../../categories/interface/Category.interface';
import DropzoneComponent from '../../../components/dropzone/Dropzone.component';
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum';
import { useTranslation } from 'react-i18next';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import { Translation } from '../../../utility/helpers/translation.helper';
import { validate } from '../../../utility/helpers/form-validation.helper';
import { getSellerProductAction, updateSellerProductAction, uploadGallayImageAction, uploadMainImageAction } from '../actions/product.actions';
import { useParams } from 'react-router-dom';
import { IOptions, IProduct } from '../interface/product.interface';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';
import { getSellerKitchenAction } from '../../kitchen/actions/kitchen.actions';

const ProductEditPage: React.FC<any> = () => {
	const assetsUrl = process.env.REACT_APP_ASSETS_URL;

	const [mainImage, setMainImage] = useState('/assets/images/placeholder.png');
	const [mainPreview, setMainPreview] = useState('/assets/images/placeholder.png');
	const [mainChange, setMainChange] = useState(false);
	const [gallaryImages, setGallaryImages] = useState([]);
	const [gallaryChange, setGallaryChange] = useState(false);
	const [categories, setCategories] = useState([] as Array<ICategory>);

	const { attributes, status: attributeStatus } = useAppSelector((state) => state.attribute);
	const { kitchen, kitchenStatus } = useAppSelector((state) => state.kitchen);
	const params = useParams();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const breadcrumb: Breadcrumb = {
		title: t('Products'),
		links: [
			{ name: t('Dashboard'), path: '/' },
			{ name: t('Products'), path: '/products/list' },
			{ name: t('Edit'), path: '/products/edit/' + params.id }
		]
	};

	const [formData, setFormData] = useState({} as IProduct);

	useEffect(() => {
		dispatch(getSellerProductAction(params.id!)).then((response: any) => {
			setFormData(response.payload.data);
			setMainPreview(assetsUrl + response.payload.data?.mainImage);
		});

		if (kitchenStatus === REDUX_STATUS.IDLE) {
			dispatch(getSellerKitchenAction()).then((res: any) => {
				setCategories(res.payload.data.categories);
			});
		}

		if (attributeStatus == REDUX_STATUS.IDLE) {
			dispatch(
				getAttributesAction({
					offset: 1,
					limit: 12,
					order: {
						orderBy: 'createdAt',
						orderDirection: SORT_DIRECTION.ASC
					}
				})
			);
		}
	}, [assetsUrl, attributeStatus, categories, dispatch, kitchen, kitchenStatus, params.id]);

	const changeHandler = (event: any, index?: number) => {
		const { name, value } = event.target;

		switch (name) {
			case 'price':
			case 'stock':
			case 'discountValue': {
				setFormData((prevFormData) => ({
					...prevFormData,
					options: [
						...prevFormData.options.map((item: IOptions, i: number) => {
							if (i == index) {
								return {
									...item,
									[name]: Number(value)
								};
							} else {
								return item;
							}
						})
					]
				}));
				break;
			}
			case 'value':
			case `discountType${index}`: {
				setFormData((prevFormData) => ({
					...prevFormData,
					options: [
						...prevFormData.options.map((item: any, i: number) => {
							if (i == index) {
								return name == `discountType${index}`
									? {
										...item,
										discountType: value
									}
									: {
										...item,
										[name]: value
									};
							} else {
								return item;
							}
						})
					]
				}));
				break;
			}
			case 'attribute': {
				setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
				setFormData((prevFormData) => ({
					...prevFormData,
					options: [
						{
							price: 0,
							value: '',
							stock: null,
							discountType: DISCOUNT_TYPE.NO_DISCOUNT,
							discountValue: 0
						}
					],
					addons: [
						{
							name: {
								En: '',
								Ar: ''
							},
							price: 0,
							isOptional: true
						}
					]
				}));
				break;
			}
			case 'nameEn': {
				setFormData((prevFormData) => ({ ...prevFormData, name: { ...prevFormData.name, En: value } }));
				break;
			}
			case 'nameAr': {
				setFormData((prevFormData) => ({ ...prevFormData, name: { ...prevFormData.name, Ar: value } }));
				break;
			}
			case 'descriptionEn': {
				setFormData((prevFormData) => ({ ...prevFormData, description: { ...prevFormData.description, En: value } }));
				break;
			}
			case 'descriptionAr': {
				setFormData((prevFormData) => ({ ...prevFormData, description: { ...prevFormData.description, Ar: value } }));
				break;
			}
			case 'addonNameEn': {
				setFormData((prevFormData) => ({
					...prevFormData,
					addons: [
						...prevFormData.addons.map((item: any, i: number) => {
							if (i == index) {
								item.name.En = value;
							}
							return item;
						})
					]
				}));
				break;
			}
			case 'addonNameAr': {
				setFormData((prevFormData) => ({
					...prevFormData,
					addons: [
						...prevFormData.addons.map((item: any, i: number) => {
							if (i == index) {
								item.name.Ar = value;
							}
							return item;
						})
					]
				}));
				break;
			}
			case 'addonPrice': {
				setFormData((prevFormData) => ({
					...prevFormData,
					addons: [
						...prevFormData.addons.map((item: any, i: number) => {
							if (i == index) {
								item.price = value;
							}
							return item;
						})
					]
				}));
				break;
			}
			case 'isOptional' + index: {
				setFormData((prevFormData) => ({
					...prevFormData,
					addons: [
						...prevFormData.addons.map((item: any, i: number) => {
							if (i == index) {
								return { ...item, isOptional: JSON.parse(value) };
							} else {
								return item;
							}
						})
					]
				}));
				break;
			}
			default: {
				setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
			}
		}
	};

	const addOptionValue = () => {
		setFormData((prevFormData) => ({
			...prevFormData,
			options: [
				...formData.options,
				{
					price: 1,
					value: '',
					stock: null,
					discountType: DISCOUNT_TYPE.NO_DISCOUNT,
					discountValue: 0
				}
			]
		}));
	};

	const removeOptionValue = (index: number) => {
		formData.options.splice(index, 1);
		setFormData((prevFormData) => ({
			...prevFormData,
			options: [...formData.options]
		}));
	};

	const addProductAddon = () => {
		setFormData((prevFormData) => ({
			...prevFormData,
			addons: [
				...formData.addons,
				{
					name: {
						En: '',
						Ar: ''
					},
					price: 0,
					isOptional: true
				}
			]
		}));
	};

	const mainUploadHandler = (e: any) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onloadend = () => {
			setMainPreview(reader.result! as string);
		};
		reader.readAsDataURL(file);
		setMainImage(e.target.files[0]);
		setMainChange(true);
	};

	const gallaryUploadHandler = (e: any) => {
		if (e.length > 0) {
			setGallaryImages(e);
			setGallaryChange(true);
		}
	};

	const removeAddon = (index: number) => {
		formData.addons.splice(index, 1);
		setFormData((prevFormData) => ({
			...prevFormData,
			addons: [...formData.addons]
		}));
	};

	const submitHandler = () => {
		validate(document.getElementsByClassName('needs-validation')[0] as HTMLFormElement).then((validate) => {
			if (validate) {
				dispatch(updateSellerProductAction(formData)).then((update: any) => {
					if (update.payload.success) {
						if (gallaryChange) {
							const data: FormData = new FormData();
							for (const item in gallaryImages) {
								data.append('files', gallaryImages[item]);
							}
							data.append('fieldName', 'gallary');
							dispatch(uploadGallayImageAction({ id: update.payload.data._id, image: data }));
						}
						if (mainChange) {
							const data = new FormData();
							data.append('file', mainImage);
							data.append('fieldName', 'mainImage');
							dispatch(uploadMainImageAction({ id: update.payload.data._id, image: data }));
						}
					}
				});
			}
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<BreadcrumbComponent breadcrumb={breadcrumb} />
			</div>

			<form id="createproduct-form" autoComplete="off" className="needs-validation" noValidate>
				<div className="row">
					<div className="col-lg-8">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title mb-0">{t('GeneralInfo')}</h5>
							</div>
							<div className="card-body">
								<div className="tab-content">
									<div className="row">
										<div className="col-md-6">
											<div className="mb-3">
												<label className="form-label" htmlFor="product-title-input">
													{t('NameAr')}
												</label>
												<input
													type="text"
													className="form-control"
													id="product-title-input"
													placeholder={t('NameAr')}
													name="nameAr"
													value={formData.name?.Ar}
													onChange={changeHandler}
													required
												/>
												<div className="invalid-feedback">{t('NameError')}</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="form-label" htmlFor="product-title-input">
													{t('NameEn')}
												</label>
												<input
													type="text"
													className="form-control"
													id="product-title-input"
													value={formData.name?.En}
													placeholder={t('NameEn')}
													name="nameEn"
													onChange={changeHandler}
													required
												/>
												<div className="invalid-feedback">{t('NameError')}</div>
											</div>
										</div>
										<div className='row'>
											<div className="col-md-12">
												<div className="mb-3">
													<label>{t('ShortDescriptionAr')}</label>
													<textarea
														className="form-control"
														placeholder={t('ShortDescriptionAr')}
														rows={3}
														value={formData.description?.Ar}
														name="descriptionAr"
														onChange={changeHandler}></textarea>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12">
												<div className="mb-3">
													<label>{t('ShortDescriptionEn')}</label>
													<textarea
														className="form-control"
														placeholder={t('ShortDescriptionEn')}
														rows={3}
														value={formData.description?.En}
														name="descriptionEn"
														onChange={changeHandler}></textarea>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="row mb-2">
												<div className="col-md-6">
													<label className="form-label" htmlFor="product-title-input">
														{t('DelivaryType')}
													</label>
													<div className="mt-4 mt-lg-0">
														<div className="form-check form-check-inline">
															<input
																className="form-check-input"
																type="radio"
																name="delivaryType"
																id="group"
																value={DELIVARY_TYPE.GROUP_ORDER}
																onChange={changeHandler}
																checked={formData.delivaryType == DELIVARY_TYPE.GROUP_ORDER}
															/>
															<label className="form-check-label" htmlFor="group">
																{t('GroupOrder')}
															</label>
														</div>
														<div className="form-check form-check-inline">
															<input
																className="form-check-input"
																type="radio"
																name="delivaryType"
																id="single"
																value={DELIVARY_TYPE.SINGLE_ORDER}
																onChange={changeHandler}
																checked={formData.delivaryType == DELIVARY_TYPE.SINGLE_ORDER}
															/>
															<label className="form-check-label" htmlFor="single">
																{t('SingleOrder')}
															</label>
														</div>
													</div>
												</div>
												{formData.delivaryType == DELIVARY_TYPE.GROUP_ORDER && (
													<div className="col-md-6">
														<label className="form-label" htmlFor="product-title-input">
															{t('DeliverdDate')}
														</label>
														<Flatpickr
															options={{ minDate: new Date() }}
															className="form-control"
															value={formData.delivaryDate!}
															onChange={([date]: any) => {
																changeHandler({ target: { name: 'delivaryDate', value: date } });
															}}
														/>
													</div>
												)}
											</div>
											<div className="row">
												<div className="col-md-6">
													<label className="form-label" htmlFor="attributes">
														{t('Attribute')}
													</label>
													<select
														className="form-select"
														id="attributes"
														name="attribute"
														value={formData.attribute?._id}
														onChange={changeHandler}>
														{attributes?.map((attr) => {
															return (
																<option key={attr._id} value={attr._id}>
																	{Translation(attr.name)}
																</option>
															);
														})}
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{formData.options?.length > 0 && (
							<div className="card">
								<div className="card-header d-flex justify-content-between">
									<ul className="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
										<li className="nav-item">
											<a className="nav-link active" data-bs-toggle="tab" href="#variants" role="tab">
												{t('VariantsInfo')}
											</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" data-bs-toggle="tab" href="#addons" role="tab">
												{t('Addons')}
											</a>
										</li>
									</ul>
								</div>

								<div className="card-body">
									<div className="tab-content">
										<div className="tab-pane active" id="variants" role="tabpanel">
											{formData.options?.map((val: IOptions, x: number) => (
												<div key={x} className="border-bottom mb-4 pb-3">
													<div className="row">
														<div className="col-md-12">
															<div className="d-flex justify-content-end">
																<button type='button' className="btn btn-danger" onClick={() => removeOptionValue(x)}>
																	<i className="mdi mdi-trash-can"></i>
																</button>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="mb-3">
																<label className="form-label" htmlFor={'value' + x}>
																	{t('Value')}
																</label>
																<input
																	type="text"
																	className="form-control"
																	id={'value' + x}
																	placeholder={t('Value')}
																	name="value"
																	value={formData.options[x].value}
																	onChange={(e) => changeHandler(e, x)}
																	required
																/>
																<div className="invalid-feedback">{t('ValueError')}</div>
															</div>
														</div>
														<div className="col-md-6">
															<div className="mb-3">
																<label className="form-label" htmlFor={'stock' + x}>
																	{t('Stock')}
																</label>
																<input
																	type="number"
																	className="form-control"
																	id={'stock' + x}
																	placeholder={t('Stock')}
																	name="stock"
																	value={formData.options[x].stock!}
																	onChange={(e) => changeHandler(e, x)}
																/>
																<div className="invalid-feedback">{t('StockError')}</div>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<label className="form-label" htmlFor={'discountType' + x}>
																{t('DiscountType')}
															</label>
															<div className="mt-4 mt-lg-0">
																<div className="form-check form-check-inline">
																	<label className="form-check-label">
																		<input
																			className="form-check-input"
																			type="radio"
																			name={'discountType' + x}
																			value={DISCOUNT_TYPE.NO_DISCOUNT}
																			checked={formData.options[x].discountType == DISCOUNT_TYPE.NO_DISCOUNT}
																			onChange={(e) => changeHandler(e, x)}
																		/>
																		{t('NO_DISCOUNT')}
																	</label>
																</div>
																<div className="form-check form-check-inline">
																	<input
																		className="form-check-input"
																		type="radio"
																		name="discountType"
																		id={'fixed' + x}
																		value={DISCOUNT_TYPE.FIXED}
																		checked={formData.options[x].discountType == DISCOUNT_TYPE.FIXED}
																		onChange={(e) => changeHandler(e, x)}
																	/>
																	<label className="form-check-label">{t('Fixed')}</label>
																</div>
																<div className="form-check form-check-inline">
																	<label className="form-check-label">
																		<input
																			className="form-check-input"
																			type="radio"
																			name={'discountType' + x}
																			value={DISCOUNT_TYPE.PERCENT}
																			checked={formData.options[x].discountType == DISCOUNT_TYPE.PERCENT}
																			onChange={(e) => changeHandler(e, x)}
																		/>
																		{t('Percent')}
																	</label>
																</div>
															</div>
														</div>
														<div className="col-lg-3 col-sm-6">
															<div className="mb-3">
																<label className="form-label">{t('Price')}</label>
																<div className="input-group has-validation mb-3">
																	<span className="input-group-text">{t('CurrencyCode')}</span>
																	<input
																		type="number"
																		className="form-control"
																		placeholder={t('Price')}
																		name="price"
																		value={formData.options[x].price}
																		onChange={(e) => changeHandler(e, x)}
																		required
																	/>
																	<div className="invalid-feedback">{t('PriceError')}</div>
																</div>
															</div>
														</div>
														{formData.options[x].discountType != DISCOUNT_TYPE.NO_DISCOUNT && (
															<div className="col-lg-3 col-sm-6">
																<div className="mb-3">
																	<label className="form-label">{t('Discount')}</label>
																	<div className="input-group mb-3">
																		<span className="input-group-text">{formData.options[x].discountType == DISCOUNT_TYPE.FIXED ? t('CurrencyCode') : '%'}</span>
																		<input
																			type="number"
																			className="form-control"
																			name="discountValue"
																			value={formData.options[x].discountValue}
																			onChange={(e) => changeHandler(e, x)}
																			placeholder={t('Discount')}
																			required
																		/>
																	</div>
																</div>
															</div>
														)}
													</div>
												</div>
											))}
											<button type="button" id="add-item" className="btn btn-secondary fw-medium" onClick={addOptionValue}>
												<i className="ri-add-fill me-1 align-bottom"></i>
											</button>
										</div>
										<div className="tab-pane" id="addons" role="tabpanel">
											{formData.addons?.map((_, x: number) => (
												<div key={x} className="border-bottom mb-4 pb-3">
													<div className="row">
														<div className="col-md-12">
															<div className="d-flex justify-content-end">
																<button type='button' className="btn btn-danger" onClick={() => removeAddon(x)}>
																	<i className="mdi mdi-trash-can"></i>
																</button>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="mb-3">
																<label className="form-label" htmlFor={'addonNameEn' + x}>
																	{t('NameEn')}
																</label>
																<input
																	type="text"
																	className="form-control"
																	id={'addonNameEn' + x}
																	placeholder={t('NameEn')}
																	name="addonNameEn"
																	value={formData.addons[x].name.En}
																	onChange={(e) => changeHandler(e, x)}
																/>
																<div className="invalid-feedback">{t('NameError')}</div>
															</div>
														</div>
														<div className="col-md-6">
															<div className="mb-3">
																<label className="form-label" htmlFor={'addonNameAr' + x}>
																	{t('NameAr')}
																</label>
																<input
																	type="text"
																	className="form-control"
																	id={'addonNameAr' + x}
																	placeholder={t('NameAr')}
																	name="addonNameAr"
																	value={formData.addons[x].name.Ar}
																	onChange={(e) => changeHandler(e, x)}
																/>
																<div className="invalid-feedback">{t('NameError')}</div>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<label className="form-label" htmlFor={'addonOptional' + x}>
																{t('IsOptional')}
															</label>
															<div className="mt-4 mt-lg-0">
																<div className="form-check form-check-inline">
																	<label className="form-check-label">
																		<input
																			className="form-check-input"
																			type="radio"
																			name={'isOptional' + x}
																			id={'optional' + x}
																			value="true"
																			checked={formData.addons[x].isOptional}
																			onChange={(e) => changeHandler(e, x)}
																		/>
																		{t('Optional')}
																	</label>
																</div>

																<div className="form-check form-check-inline">
																	<label className="form-check-label">
																		<input
																			className="form-check-input"
																			type="radio"
																			name={'isOptional' + x}
																			value="false"
																			checked={!formData.addons[x].isOptional}
																			onChange={(e) => changeHandler(e, x)}
																		/>
																		{t('Required')}
																	</label>
																</div>
															</div>
														</div>
														<div className="col-md-6">
															<div className="mb-3">
																<label className="form-label" htmlFor={'addonPrice' + x}>
																	{t('Price')}
																</label>
																<div className="input-group has-validation mb-3">
																	<span className="input-group-text" id={'addonPrice' + x}>
																		{t('CurrencyCode')}
																	</span>
																	<input
																		type="number"
																		className="form-control"
																		id={'addonPrice' + x}
																		placeholder={t('Price')}
																		name="addonPrice"
																		value={formData.addons[x].price}
																		onChange={(e) => changeHandler(e, x)}
																	/>
																	<div className="invalid-feedback">{t('PriceError')}</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											))}
											<button type="button" id="add-item" className="btn btn-secondary fw-medium" onClick={addProductAddon}>
												<i className="ri-add-fill me-1 align-bottom"></i>
											</button>
										</div>
									</div>
								</div>
							</div>
						)}

						<div className="card">
							<div className="card-header">
								<h5 className="card-title mb-0">{t('ProductGallery')}</h5>
							</div>
							<div className="card-body">
								<DropzoneComponent uploadedFiles={gallaryUploadHandler} images={formData.gallary}></DropzoneComponent>
							</div>
						</div>
					</div>

					<div className="col-lg-4">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title mb-0">{t('MainImage')}</h5>
							</div>
							<div className="card-body">
								<div className="mb-4">
									<div className="text-center">
										<div className="position-relative d-inline-block">
											<div className="position-absolute top-100 start-100 translate-middle">
												<label htmlFor="logo-image" className="mb-0" data-bs-toggle="tooltip" data-bs-placement="right" title="Select Logo">
													<div className="avatar-xs">
														<div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
															<i className="ri-image-fill"></i>
														</div>
													</div>
												</label>
												<input
													className="form-control d-none"
													id="logo-image"
													type="file"
													accept="image/png, image/gif, image/jpeg"
													onChange={mainUploadHandler}
												/>
											</div>
											<div className="avatar-lg">
												<div className="avatar-title bg-light rounded">
													<img
														className="avatar-xl h-auto"
														src={mainPreview}
														alt="Product-Image"
														onLoad={() => {
															URL.revokeObjectURL(mainPreview);
														}}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="card">
							<div className="card-header">
								<h5 className="card-title mb-0">{t('Category')}</h5>
							</div>
							<div className="card-body">
								<select className="form-select" id="category" value={formData.category?._id} name="category" onChange={changeHandler}>
									{categories?.map((category: ICategory) => (
										<option key={category._id} value={category._id}>
											{Translation(category.name)}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-8">
						<div className="card">
							<div className="card-body">
								<div className="text-end mb-3">
									<button type="button" className="btn btn-success w-sm" onClick={submitHandler}>
										{t('Update')}
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="col-m-3"></div>
				</div>
			</form>
		</div>
	);
};

export default ProductEditPage;
