import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface';
import { useTranslation } from 'react-i18next';
import { UploadGallaryAction, getSellerKitchenAction, updateKitchenAction, uploadImageAction } from '../actions/kitchen.actions';
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component';
import { getAllCitiesAction, getAllGovernoratesAction } from '../../../common/lookups/actions/lookup.action';
import { Translation } from '../../../utility/helpers/translation.helper';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import Multiselect from 'multiselect-react-dropdown';
import { validate } from '../../../utility/helpers/form-validation.helper';
import { GoogleMapsComponent } from '../../../components/google-maps/GoogleMaps.component';
import { IKitchen } from '../interface/kitchen.interface';
import DropzoneComponent from '../../../components/dropzone/Dropzone.component';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';
import { getSellerCategoriesAction } from '../../categories/action/Category.action';
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum';

const KitchenEditPage: React.FC = () => {
	const assets = process.env.REACT_APP_ASSETS_URL;
	const { t, i18n } = useTranslation();
	const dispatch = useAppDispatch();
	const { governorates, cities } = useAppSelector((state) => state.lookup);
	const { categories, status } = useAppSelector((state) => state.category);
	const [logoImage, setLogoImage] = useState('/assets/images/logo.png');
	const [logoChange, setLogoChange] = useState(false);
	const [bannerImage, setBannerImage] = useState('/assets/images/placeholder.png');
	const [bannerChange, setBannerChange] = useState(false);
	const [bannerImageHolder, setBannerImageHolder] = useState('/assets/images/placeholder.png');
	const [logoImageHolder, setLogoImageHolder] = useState('/assets/images/placeholder.png');
	const { kitchen } = useAppSelector((state) => state.kitchen);
	const [selectedCats, setSelectedCats] = useState([] as any);
	const [gallaryImages, setGallaryImages] = useState([]);
	const [location, setLocation] = useState({
		type: 'Point',
		coordinates: [30.0443953, 31.233081]
	});
	const [gallaryChange, setGallaryChange] = useState(false);
	const [weekDays] = useState([
		{ En: 'Saturday', Ar: 'السبت' },
		{ En: 'Sunday', Ar: 'اﻷحد' },
		{ En: 'Monday', Ar: 'اﻷثنين' },
		{ En: 'Tuesday', Ar: 'الثلاثاء' },
		{ En: 'Wednesday', Ar: 'اﻷربعاء' },
		{ En: 'Thursday', Ar: 'الخميس' },
		{ En: 'Friday', Ar: 'الجمعه' }
	]);
	const [kitchenForm, setKitchenForm] = useState({
		_id: '',
		name: { En: '', Ar: '' },
		delivaryCharge: 0,
		requireDeposit: false,
		specialMeal: false,
		deposit: 0,
		isActive: false,
		gallary: [],
		openTime: 0,
		closeTime: 0,
		address: '',
		description: { En: '', Ar: '' },
		owner: '',
		logo: '',
		location: {
			type: 'Point',
			coordinates: [0, 0]
		},
		workingDays: [''],
		banner: '',
		rating: 0,
		city: '',
		governorate: '',
		categories: ['']
	});
	const [catData, setCatData] = useState([] as any);

	const breadcrumb: Breadcrumb = {
		title: t('Kitchens'),
		links: [
			{ name: t('Dashboard'), path: '/' },
			{ name: t('Kitchens'), path: '/kitchens/list' },
			{ name: t('Edit'), path: '/kitchens/edit' }
		]
	};

	useEffect(() => {
		if (!governorates) {
			dispatch(getAllGovernoratesAction());
		}
		if (status == REDUX_STATUS.IDLE) {
			dispatch(
				getSellerCategoriesAction({
					offset: 1,
					limit: 12,
					order: {
						orderBy: 'createdAt',
						orderDirection: SORT_DIRECTION.ASC
					}
				})
			);
		}
		if (categories) {
			const newCats = categories.map((cat) => {
				return {
					id: cat._id,
					En: cat.name.En,
					Ar: cat.name.Ar
				};
			});
			setCatData(newCats);
		}
	}, [categories, dispatch, governorates, status]);

	useEffect(() => {
		if (Object.keys(kitchen).length) {
			setKitchenForm({
				_id: kitchen._id,
				name: kitchen.name,
				delivaryCharge: kitchen.delivaryCharge,
				requireDeposit: kitchen.requireDeposit,
				specialMeal: kitchen.specialMeal,
				deposit: kitchen.deposit,
				isActive: kitchen.isActive,
				gallary: kitchen.gallary,
				openTime: kitchen.openTime,
				closeTime: kitchen.closeTime,
				address: kitchen.address,
				description: kitchen.description,
				owner: kitchen.owner._id,
				logo: kitchen.logo,
				location: kitchen.location,
				workingDays: kitchen.workingDays,
				banner: kitchen.banner,
				rating: kitchen.rating,
				city: kitchen.city._id,
				governorate: kitchen.governorate._id,
				categories: [...kitchen.categories.map((cat) => cat._id)]
			});
			dispatch(getAllCitiesAction(kitchen.governorate._id));
			setBannerImageHolder(kitchen.banner);
			setLogoImageHolder(kitchen.logo);
			setLocation(kitchen.location);
			setGallaryImages(kitchen.gallary);
		} else {
			dispatch(getSellerKitchenAction());
		}

		if (categories && Object.keys(kitchen).length) {
			setSelectedCats([]);
			categories.map((cat) => {
				if (kitchen.categories.findIndex(item => item._id == cat._id) !== -1) {
					setSelectedCats((prev: any) => [
						...prev,
						{
							_id: cat._id,
							En: cat.name.En,
							Ar: cat.name.Ar
						}
					]);
				}
			});
		}
	}, [categories, dispatch, kitchen, status]);

	const changeHandler = (event: any) => {
		const { name, value } = event.target;
		switch (name) {
			case 'nameEn': {
				setKitchenForm((prevFormData) => ({ ...prevFormData, name: { ...prevFormData.name, En: value } }));
				break;
			}
			case 'nameAr': {
				setKitchenForm((prevFormData) => ({ ...prevFormData, name: { ...prevFormData.name, Ar: value } }));
				break;
			}
			case 'descriptionEn': {
				setKitchenForm((prevFormData) => ({ ...prevFormData, description: { ...prevFormData.description, En: value } }));
				break;
			}
			case 'descriptionAr': {
				setKitchenForm((prevFormData) => ({ ...prevFormData, description: { ...prevFormData.description, Ar: value } }));
				break;
			}
			case 'governorate': {
				dispatch(getAllCitiesAction(value));
				setKitchenForm((prevFormData) => ({ ...prevFormData, governorate: value }));
				break;
			}
			case 'isActive':
			case 'requireDeposit':
			case 'specialMeal': {
				setKitchenForm((prevFormData) => ({ ...prevFormData, [name]: JSON.parse(value) }));
				break;
			}
			case 'categories': {
				setKitchenForm((prevFormData) => ({
					...prevFormData,
					[name]: value.map((val: any) => {
						return val.id;
					})
				}));
				break;
			}
			default: {
				setKitchenForm((prevFormData) => ({ ...prevFormData, [name]: value }));
				break;
			}
		}
	};

	const logoUploadHandler = (e: any) => {
		const file = e.target.files[0];
		setLogoImage(file);
		const reader = new FileReader();
		reader.onloadend = () => {
			setLogoImageHolder(reader.result! as string);
		};
		reader.readAsDataURL(file);
		setLogoChange(true);
	};

	const bannerUploadHandler = (e: any) => {
		const file = e.target.files[0];
		setBannerImage(file);
		const reader = new FileReader();
		reader.onloadend = () => {
			setBannerImageHolder(reader.result! as string);
		};
		reader.readAsDataURL(file);
		setBannerChange(true);
	};

	const gallaryUploadHandler = (e: any) => {
		if (e.length > 0) {
			setGallaryImages(e);
			setGallaryChange(true);
		}
	};

	const submitHandler = () => {
		validate(document.getElementsByClassName('needs-validation')[0] as HTMLFormElement).then((validate) => {
			if (validate) {
				dispatch(updateKitchenAction({ id: kitchenForm._id!, formData: { ...kitchenForm, location } }));
				if (bannerChange) {
					const data = new FormData();
					data.append('file', bannerImage);
					data.append('fieldName', 'banner');
					dispatch(uploadImageAction({ id: kitchenForm._id, image: data }));
				}
				if (logoChange) {
					const data = new FormData();
					data.append('file', logoImage);
					data.append('fieldName', 'logo');
					dispatch(uploadImageAction({ id: kitchenForm._id, image: data }));
				}

				if (gallaryChange) {
					const data: FormData = new FormData();
					for (const item in gallaryImages) {
						data.append('files', gallaryImages[item]);
					}
					data.append('fieldName', 'gallary');
					dispatch(UploadGallaryAction({ id: kitchenForm._id, image: data }));
				}
			}
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<BreadcrumbComponent breadcrumb={breadcrumb} />
			</div>

			<form className="form-steps needs-validation" autoComplete="off">
				<div className="row">
					<div className="col-md-8">
						<div className="card">
							<div className="card-header">
								<ul className="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
									<li className="nav-item">
										<a className="nav-link active" data-bs-toggle="tab" href="#general" role="tab">
											{t('GeneralInfo')}
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" data-bs-toggle="tab" href="#location" role="tab">
											{t('Location')}
										</a>
									</li>
								</ul>
							</div>

							<div className="card-body">
								<div className="tab-content">
									<div className="tab-pane active" id="general" role="tabpanel">

										<div className="row mb-3">
											<div className="col-md-6">
												<label className="form-label" htmlFor="nameAr">
													{t('KitchenNameAr')}
												</label>
												<input
													type="text"
													className="form-control"
													id="nameAr"
													name="nameAr"
													value={kitchenForm.name?.Ar}
													placeholder={t('KitchenNameAr')}
													minLength={2}
													onChange={changeHandler}
													required
												/>
												<div className="invalid-feedback">{t('KitchenName')}</div>
											</div>
											<div className="col-md-6">
												<label className="form-label" htmlFor="nameEn">
													{t('KitchenNameEn')}
												</label>
												<input
													type="text"
													className="form-control"
													id="nameEn"
													name="nameEn"
													value={kitchenForm.name?.En}
													placeholder={t('KitchenNameEn')}
													onChange={changeHandler}
													minLength={2}
													required
												/>
												<div className="invalid-feedback">{t('KitchenName')}</div>
											</div>
										</div>
										<div className="row mb-3">
											<div className="col-md-12">
												<label className="form-label" htmlFor="descriptionAr">
													{t('DescriptionAr')}
												</label>
												<textarea
													className="form-control"
													placeholder={t('DescriptionAr')}
													id="descriptionAr"
													name="descriptionAr"
													value={kitchenForm.description?.Ar}
													onChange={changeHandler}
													rows={3}
													required></textarea>
												<div className="invalid-feedback">{t('DescriptionError')}</div>
											</div>
										</div>
										<div className="row mb-3">
											<div className="col-md-12">
												<label className="form-label" htmlFor="descriptionEn">
													{t('DescriptionEn')}
												</label>
												<textarea
													className="form-control"
													placeholder={t('DescriptionEn')}
													id="descriptionEn"
													value={kitchenForm.description?.En}
													name="descriptionEn"
													onChange={changeHandler}
													rows={3}
													required></textarea>
												<div className="invalid-feedback">{t('DescriptionError')}</div>
											</div>
										</div>

										<div className="row mb-3">
											<div className="col-md-6">
												<label className="form-label" htmlFor="governorate">
													{t('Governorate')}
												</label>
												<select
													className="form-select"
													value={kitchenForm.governorate}
													id="governorate"
													name="governorate"
													onChange={changeHandler}
													required>
													{governorates?.map((govern) => (
														<option key={govern._id} value={govern._id}>
															{Translation(govern.name)}
														</option>
													))}
												</select>
											</div>
											<div className="col-md-6">
												<label className="form-label" htmlFor="city">
													{t('City')}
												</label>
												<select className="form-select" id="city" value={kitchenForm.city} name="city" onChange={changeHandler} required>
													{cities?.map((city) => (
														<option key={city._id} value={city._id}>
															{Translation(city.name)}
														</option>
													))}
												</select>
											</div>
										</div>
										<div className="row mb-3">
											<div className="col-md-6">
												<label className="form-label" htmlFor="delivaryCharge">
													{t('DelivaryCharges')}
												</label>
												<div className="input-group">
													<input
														type="number"
														className="form-control"
														name="delivaryCharge"
														value={kitchenForm.delivaryCharge}
														id="delivaryCharge"
														placeholder={t('DelivaryCharges')}
														onChange={changeHandler}
														min={0}
														required
													/>
													<span className="input-group-text">{t('CurrencyCode')}</span>
												</div>
											</div>

											<div className="col-md-6">
												<label className="form-label" htmlFor="isActive">
													{t('IsActive')}
												</label>
												<br />
												<div className="form-check-inline mb-2">
													<input
														className="form-check-input"
														type="radio"
														name="isActive"
														id="active"
														value="true"
														checked={kitchenForm.isActive}
														onChange={changeHandler}
													/>
													<label className="form-check-label mx-2" htmlFor="active">
														{t('Active')}
													</label>
												</div>
												<div className="form-check-inline mb-2">
													<input
														className="form-check-input"
														type="radio"
														name="isActive"
														id="inactive"
														value="false"
														checked={!kitchenForm.isActive}
														onChange={changeHandler}
													/>
													<label className="form-check-label mx-2" htmlFor="inactive">
														{t('InActive')}
													</label>
												</div>
											</div>
										</div>
										<div className="row mb-3">
											<div className="col-md-6">
												<label className="form-label" htmlFor="RequireDeposit">
													{t('RequireDeposit')}
												</label>
												<br />
												<div className="form-check-inline mb-2">
													<input
														className="form-check-input"
														type="radio"
														name="requireDeposit"
														id="requireyes"
														checked={kitchenForm.requireDeposit}
														value="true"
														onChange={changeHandler}
													/>
													<label className="form-check-label mx-2" htmlFor="requireyes">
														{t('Yes')}
													</label>
												</div>
												<div className="form-check-inline mb-2">
													<input
														className="form-check-input"
														type="radio"
														name="requireDeposit"
														id="requireno"
														checked={!kitchenForm.requireDeposit}
														value="false"
														onChange={changeHandler}
													/>
													<label className="form-check-label mx-2" htmlFor="requireno">
														{t('No')}
													</label>
												</div>
											</div>
											{kitchenForm.requireDeposit && (
												<div className="col-md-6">
													<label className="form-label" htmlFor="deposit">
														{t('Deposit')}
													</label>
													<div className="input-group">
														<input
															type="number"
															className="form-control"
															name="deposit"
															value={kitchenForm.deposit}
															id="deposit"
															placeholder={t('Deposit')}
															onChange={changeHandler}
															min={1}
															required
														/>
														<span className="input-group-text">%</span>
													</div>
												</div>
											)}
										</div>
										<div className="row mb-3">
											<div className="col-md-6">
												<label className="form-label" htmlFor="acceptSpecialOrders">
													{t('AcceptSpecialOrders')}
												</label>
												<br />
												<div className="form-check-inline mb-2">
													<input
														className="form-check-input"
														type="radio"
														name="specialMeal"
														id="specialyes"
														checked={kitchenForm.specialMeal}
														value="true"
														onChange={changeHandler}
													/>
													<label className="form-check-label mx-2" htmlFor="specialyes">
														{t('Yes')}
													</label>
												</div>
												<div className="form-check-inline mb-2">
													<input
														className="form-check-input"
														type="radio"
														name="specialMeal"
														id="specialno"
														checked={!kitchenForm.specialMeal}
														value="false"
														onChange={changeHandler}
													/>
													<label className="form-check-label mx-2" htmlFor="specialno">
														{t('No')}
													</label>
												</div>
											</div>
											<div className="col-md-6">
												<label className="form-label" htmlFor="categories">
													{t('Categories')}
												</label>
												<Multiselect
													id="categories"
													options={catData}
													selectedValues={selectedCats}
													placeholder={t('Categories')}
													onSelect={(e) => changeHandler({ target: { name: 'categories', value: e } })}
													onRemove={(e) => changeHandler({ target: { name: 'categories', value: e } })}
													displayValue={i18n.language === 'En' ? 'En' : 'Ar'}
												/>
											</div>
										</div>
										<div className="row mb-3">
											<div className="col-lg-6">
												<label className="form-label" htmlFor="openTime">
													{t('OpenTime')}
												</label>
												<Flatpickr
													className="form-control"
													data-enable-time
													data-no-calendar
													value={kitchenForm.openTime!}
													onChange={([date]: any) => {
														changeHandler({ target: { name: 'openTime', value: date } });
													}}
												/>
												<div className="invalid-feedback">{t('Time')}</div>
											</div>
											<div className="col-lg-6">
												<label className="form-label" htmlFor="closeTime">
													{t('CloseTime')}
												</label>
												<Flatpickr
													className="form-control"
													data-enable-time
													data-no-calendar
													value={kitchenForm.closeTime!}
													onChange={([date]: any) => {
														changeHandler({ target: { name: 'closeTime', value: date } });
													}}
												/>
												<div className="invalid-feedback">{t('Time')}</div>
											</div>
										</div>
										<div className="row mb-3">
											<div className="col-md-12">
												<label className="form-label" htmlFor="workingDays">
													{t('WorkingDays')}
												</label>
												<Multiselect
													id="workingDays"
													selectedValues={kitchenForm.workingDays}
													options={weekDays}
													placeholder={t('WorkingDays')}
													onSelect={(e) => changeHandler({ target: { name: 'workingDays', value: e } })}
													onRemove={changeHandler}
													displayValue={i18n.language === 'En' ? 'En' : 'Ar'}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12">
												<label className="form-label" htmlFor="address">
													{t('Address')}
												</label>
												<textarea
													className="form-control"
													id="address"
													rows={3}
													value={kitchenForm.address!}
													name="address"
													placeholder={t('Address')}
													onChange={changeHandler}
													minLength={2}
													required
												/>
											</div>
										</div>
									</div>
									<div className="tab-pane" id="location" role="tabpanel">
										<div className="row">
											<div className="col-md-12">
												<GoogleMapsComponent setUserLocation={setLocation} location={location} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card mt-4">
							<div className="card-header">
								<h5 className="card-title mb-0">{t('KitchenGallery')}</h5>
							</div>
							<div className="card-body">
								<DropzoneComponent uploadedFiles={gallaryUploadHandler} images={kitchenForm.gallary}></DropzoneComponent>
							</div>
						</div>

						<div className="d-flex align-items-start gap-3 mt-4 mb-4">
							<button type="button" className="btn btn-success w-sm" onClick={submitHandler}>
								{t('Save')}
							</button>
						</div>
					</div>

					<div className="col-md-4">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title mb-0">{t('Logo')}</h5>
							</div>
							<div className="card-body">
								<div className="mb-4">
									<div className="text-center">
										<div className="position-relative d-inline-block">
											<div className="position-absolute top-100 start-100 translate-middle">
												<label htmlFor="logo-image" className="mb-0" data-bs-toggle="tooltip" data-bs-placement="right" title="Select Logo">
													<div className="avatar-xs">
														<div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
															<i className="ri-image-fill"></i>
														</div>
													</div>
												</label>
												<input
													className="form-control d-none"
													id="logo-image"
													type="file"
													accept="image/png, image/gif, image/jpeg"
													onChange={logoUploadHandler}
												/>
											</div>
											<div className="avatar-lg">
												<div className="avatar-title bg-light rounded">
													<img src={assets + logoImageHolder} id="category-img" className="avatar-xl" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header">
								<h5 className="card-title mb-0">{t('Banner')}</h5>
							</div>
							<div className="card-body">
								<div className="mb-4">
									<div className="text-center">
										<div className="position-relative d-inline-block">
											<div className="position-absolute top-100 start-50 translate-middle">
												<label htmlFor="banner-image" className="mb-0" data-bs-toggle="tooltip" data-bs-placement="right" title="Select Banner">
													<div className="avatar-xs">
														<div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
															<i className="ri-image-fill"></i>
														</div>
													</div>
												</label>
												<input
													className="form-control d-none"
													id="banner-image"
													type="file"
													accept="image/png, image/gif, image/jpeg"
													onChange={bannerUploadHandler}
												/>
											</div>
											<div>
												<div className="avatar-title bg-light rounded">
													<img src={assets + bannerImageHolder} id="category-img" className="w-100 h-auto" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default KitchenEditPage;
