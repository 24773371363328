import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../store/store'
import { authActions } from '../modules/auth/slice/auth.slice'
import { profileActions } from '../modules/profile/slice/profile.slice'
import { NavLink, useNavigate } from 'react-router-dom'
import { useProfile } from '../provider/Profile.provider'
import { ROUTES } from '../resources/routes-constants'
import { useTranslation } from 'react-i18next'
import { getProfileAction } from '../modules/profile/action/profile.action';

const HeaderComponent: React.FC<any> = () => {
	const assetsUrl = process.env.REACT_APP_ASSETS_URL
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
	const userProfile = useProfile()
  const [langIcon, setLangIcon] = useState('/assets/images/flags/us.svg')

  const icons = {
    ar: '/assets/images/flags/ae.svg',
    en: '/assets/images/flags/us.svg'
  }

  const language = window.localStorage.getItem('language')!

  useEffect(() => {
    if (language == 'en') {
      setLangIcon(icons.en)
      i18n.changeLanguage('en')
    } else {
      setLangIcon(icons.ar)
      i18n.changeLanguage('ar')
    }
    window.document.getElementsByTagName('html')[0].setAttribute('lang', language)
  }, [language, icons.ar, icons.en, i18n])

	useEffect(() => {
		if(!Object.keys(userProfile).length) {
			dispatch(getProfileAction())
		}
	}, [dispatch, userProfile])

  const logoutHandler = () => {
    localStorage.clear()
    dispatch(authActions.setAuth({}))
    dispatch(profileActions.setProfile({}))
    navigate('/auth/login')
  }

  const changeLanguage = (lang: string) => {
		
		const link: HTMLLinkElement = document.querySelector('[id="rtlStyle"]')!;
		const href = "/assets/css/app-rtl.min.css";

		window.document.getElementsByTagName('html')[0].setAttribute('lang', lang);
		window.document.getElementsByTagName('html')[0].setAttribute('dir', lang === 'en' ? 'ltr' : 'rtl');

		link.href = lang === 'ar' ? href : '';

    i18n.changeLanguage(lang)
    window.localStorage.setItem('language', lang)
  }

  return (
    <header id="page-topbar">
      <div className="layout-width">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box horizontal-logo">
              <a href="index.html" className="logo logo-light">
                <span className="logo-sm">
                  <img src="/assets/images/logo.png" alt="Akla Beity" />
                </span>
                <span className="logo-lg">
                  <img src="/assets/images/logo.png" alt="Akla Beity" />
                </span>
              </a>
            </div>

            <button type="button" className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon">
              <span className="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
          </div>

          <div className="d-flex align-items-center">
            <div className="dropdown ms-1 topbar-head-dropdown header-item">
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <img id="header-lang-img" src={langIcon} alt="Header Language" height="20" className="rounded" />
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <button
                  className="btn-link dropdown-item notify-item language py-2"
                  data-lang="en"
                  title="English"
                  onClick={() => changeLanguage('en')}>
                  <img src="/assets/images/flags/us.svg" alt="user-image" className="me-2 rounded" height="18" />
                  <span className="align-middle">English</span>
                </button>

                <button className="btn-link dropdown-item notify-item language" data-lang="ar" title="Arabic" onClick={() => changeLanguage('ar')}>
                  <img src="/assets/images/flags/ae.svg" alt="user-image" className="me-2 rounded" height="18" />
                  <span className="align-middle">عربى</span>
                </button>
              </div>
            </div>

            <div className="dropdown ms-sm-3 header-item topbar-user">
              <button type="button" className="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="d-flex align-items-center">
                  <img
                    className="rounded-circle header-profile-user"
                    src={assetsUrl + userProfile.photo}
                    alt="Header Avatar"
                  />
                  <span className="text-start ms-xl-2">
                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userProfile.firstName + ' ' + userProfile.lastName}</span>
                    <span className="d-none d-xl-block ms-1 fs-12 user-name-sub-text">{userProfile.role?.name}</span>
                  </span>
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <h6 className="dropdown-header">{t("Welcome")} {userProfile.firstName}</h6>
                <NavLink className="dropdown-item" to={ROUTES.PROFILE_EDIT}>
                  <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span className="align-middle">{t('Profile')}</span>
                </NavLink>
                <a className="dropdown-item" href="pages-faqs.html">
                  <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span className="align-middle">{t("Help")}</span>
                </a>
                <div className="dropdown-divider"></div>
                <button className="dropdown-item" onClick={logoutHandler}>
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle" data-key="t-logout">
                    {t("Logout")}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderComponent
