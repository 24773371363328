import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum';
import { useProfile } from '../../../provider/Profile.provider';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';
import { listSellerSpecialOrdersAction } from '../actions/special-orders.actions';
import { ISpecialOrder } from '../interfaces/special-order.interface';
import { useLoading } from '../../../provider/IsLoading.provider';
import { useNavigate } from 'react-router-dom';
import { NoResultsComponent } from '../../../components/no-results/NoResults.component';

export const SpecialOrdersListPage: React.FC<any> = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { specialOrders, status } = useAppSelector((state) => state.specialOrder);
	const userProfile = useProfile();
	const navigate = useNavigate();
	const { setLoading } = useLoading();
	const breadcrumb: Breadcrumb = {
		title: t('SpecialOrders'),
		links: [
			{ name: t('Dashboard'), path: '/' },
			{ name: t('SpecialOrders'), path: '/special-orders/list' }
		]
	};

	useEffect(() => {
		if (status == REDUX_STATUS.IDLE) {
			setLoading(true);
			dispatch(
				listSellerSpecialOrdersAction({
					page: 1,
					perPage: 12,
					orderBy: 'createdAt',
					orderDirection: SORT_DIRECTION.ASC
				})
			).then(() => setLoading(false));
		}
	}, [dispatch, setLoading, status, userProfile]);

	const navigateToDetails = (navLink: string) => {
		navigate(navLink);
	};
	
		const specialSerachHandler = (e: ChangeEvent<HTMLInputElement>) => {
			if (e.target?.value.length > 2) {
				dispatch(
					listSellerSpecialOrdersAction({
						page: 1,
						perPage: 12,
						orderBy: 'createdAt',
						orderDirection: SORT_DIRECTION.DESC,
						search: e.target.value
					})
				);
			}
		};

	return (
		<div className="container-fluid">
			<div className="row">
				<BreadcrumbComponent breadcrumb={breadcrumb} />
			</div>

			<div className="row">
				<div className="col-md-12">
					<div>
						<div className="card">
							<div className="card-header border-0">
								<div className="row g-4">
									<div className="col-sm">
										<div className="d-flex justify-content-sm-end">
											<div className="search-box ms-2">
											<input type="text" className="form-control bg-light border-0" placeholder={t("Search_Placeholder")} onChange={(e) => specialSerachHandler(e)} />
												<i className="ri-search-line search-icon"></i>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="card-body">
								<div className="tab-content text-muted">
									<div id="table-product-list-published" className="table-card">
										{specialOrders.length > 0 ?
											<div className="table-responsive">
												<table className="table align-middle table-nowrap mb-0">
													<thead className="table-light">
														<tr>
															<th scope="col">{t("ID")}</th>
															<th scope="col">{t('BudgetRange')}</th>
															<th scope="col">{t('PeopleNumber')}</th>
															<th scope="col">{t('IsActive')}</th>
															<th scope="col">{t('Status')}</th>
															<th scope="col">{t('Actions')}</th>
														</tr>
													</thead>
													<tbody>
														{specialOrders.map((order: ISpecialOrder) =>

															<tr key={order._id}>
																<td>
																	<div className="d-flex align-items-center">
																		<div className="flex-grow-1">
																			<p className="text-muted mb-0">
																				<a role='button' onClick={() => navigateToDetails('/special-orders/details/' + order._id)} className="text-body">
																					{order._id}
																				</a>
																			</p>
																		</div>
																	</div>
																</td>
																<td>{`${order.budgetRange.from} ${t('CurrencyCode')} - ${order.budgetRange.to} ${t('CurrencyCode')}`}</td>
																<td>{order.peopleNumber}</td>
																<td>{order.isActive ? (
																	<span className="badge bg-success-subtle text-success text-uppercase">{t('Active')}</span>
																) : (
																	<span className="badge bg-danger-subtle text-danger text-uppercase">{t('InActive')}</span>
																)}
																</td>
																<td>{order.status}</td>
																<td>
																	<ul className="list-inline hstack gap-2 mb-0">
																		<li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
																			<a role='button' onClick={() => navigateToDetails('/special-orders/details/' + order._id)} className="text-body">
																				<i className="ri-eye-fill fs-16"></i>
																			</a>
																		</li>
																	</ul>
																</td>
															</tr>
														)}
													</tbody>
												</table>
											</div> : <NoResultsComponent />
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
