import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import CategorySlice from '../modules/categories/slice/Category.slice';
import authSlice from '../modules/auth/slice/auth.slice';
import profileSlice from '../modules/profile/slice/profile.slice';
import productSlice from '../modules/products/slice/product.slice';
import attributeSlice from '../modules/attribute/slice/attribute.slice';
import layoutSlice from '../layout/slice/layout.slice';
import lookupSlice from '../common/lookups/slice/lookup.slice';
import kitchenSlice from '../modules/kitchen/slice/kitchen.slice';
import orderSlice from '../modules/orders/slice/order.slice';
import specialOrderSlice from '../modules/special-orders/slice/special-order.slice';
import offerSlice from '../modules/offer/slice/offer.slice';
import paymentSlice from '../modules/payments/slice/payments.slice';
import { thunk } from 'redux-thunk';

export const store = configureStore({
	reducer: {
		category: CategorySlice,
		auth: authSlice,
		profile: profileSlice,
		product: productSlice,
		attribute: attributeSlice,
		layout: layoutSlice,
		lookup: lookupSlice,
		kitchen: kitchenSlice,
		order: orderSlice,
		specialOrder: specialOrderSlice,
		offer: offerSlice,
		payment: paymentSlice
	},
	middleware: (getDefaultMiddleware: any) =>
		getDefaultMiddleware({
			thunk,
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
			}
		})
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch & any;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
