import React, { createContext } from 'react';
import { IAuth } from '../modules/auth/interface/Auth.interface';
import { useAppSelector } from '../store/store';

interface InitialAuth {
	auth: IAuth;
}

const initValue: InitialAuth = {
	auth: {} as IAuth
};

const AuthContext = createContext(initValue);

export const AuthProvider = ({ children }: any) => {
	const { contents } = useAppSelector((state: any) => state.auth);
	const authStorage = JSON.parse(localStorage.getItem('TK')!);
	const auth = authStorage ?? contents;

	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export default AuthContext;
