export const ROUTES = {
    SIGNIN: '/auth/login',
    SIGNUP: '/auth/signup',

    HOMEPAGE: '/',

    KITCHEN_LIST: '/kitchens/list',
    KITCHEN_VIEW: '/kitchens/view/:id?',
    KITCHEN_EDIT: '/kitchens/edit/:id?',

    CATEGORIES_LIST: '/categories/list',
    CATEGORIES_CREATE: '/categories/create',
    CATEGORIES_EDIT: '/categories/edit/:id',

    ATTRIBUTES_LIST: '/attributes/list',

    OFFER_LIST: '/offers/list',
    
    PRODUCTS_LIST: '/products/list',
    PRODUCTS_CREATE: '/products/create',
    PRODUCTS_EDIT: '/products/edit/:id?',
    PRODUCTS_VIEW: '/products/view/:id?',

    ORDERS_LIST: '/orders/list',
    ORDERS_DETAILS: '/orders/details/:id?',

    SPECIAL_ORDERS_LIST: '/special-orders/list',
    SPECIAL_ORDERS_DETAILS: '/special-orders/details/:id?',

    PROFILE_EDIT: '/profile/edit',

		PAYMENTS_LIST: '/payments/list'
}
