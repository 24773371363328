import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { addOfferAction, deleteOfferAction, editOfferAction, getOffersAction } from '../actions/offer.actions';
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component';
import { useTranslation } from 'react-i18next';
import { validate } from '../../../utility/helpers/form-validation.helper';
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';
import { IOffer } from '../interface/offer.interface';
import { Translation } from '../../../utility/helpers/translation.helper';
import TagsInput from '../../../components/tags-input/Tags-Input.component';
import { DISCOUNT_TYPE } from '../../../utility/enums/discount-type.enum';
import WarningComponent from '../../../components/Confirmation-modals/warning.component';
import { Link } from 'react-router-dom';
import { NoResultsComponent } from '../../../components/no-results/NoResults.component';
import { offerActions } from '../slice/offer.slice';

export const OffersListPage: React.FC = () => {
	const { t } = useTranslation();
	const breadcrumb: Breadcrumb = {
		title: t('Offers'),
		links: [
			{ name: t('Dashboard'), path: '/' },
			{ name: t('Offers'), path: '/offers/list' }
		]
	};
	const dispatch = useAppDispatch();
	const { offers, status } = useAppSelector((state) => state.offer);
	const [offerForm, setOfferForm] = useState({} as IOffer);
	const [isEdit, setIsEdit] = useState(false);
	const [deleteId, setDeleteId] = useState<string>('null');
	const modalClose = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (status === REDUX_STATUS.IDLE) {
			dispatch(
				getOffersAction({
					offset: 1,
					limit: 12,
					order: {
						orderBy: 'createdAt',
						orderDirection: SORT_DIRECTION.ASC
					}
				})
			);
		}
	}, [dispatch, status]);

	const deleteHandler = () => {
		dispatch(deleteOfferAction(deleteId!));
	};

	const viewEditHandler = async (edit: boolean, e?: any) => {
		if (edit) {
			setIsEdit(true);
			await setOfferForm({ ...e });
			setIsEdit(true);
		} else {
			await setOfferForm({} as IOffer);
			setIsEdit(false);
		}
	};

	const changeHandler = (event: ChangeEvent<any>) => {
		const { name, value } = event.target;
		if (name.includes('.')) {
			setOfferForm((prev: any) => ({ ...prev, [name.split('.')[0]]: { ...prev[name.split('.')[0]], [name.split('.')[1]]: value } }));
		} else if (name === 'isActive') {
			setOfferForm((prev) => ({ ...prev, [name]: JSON.parse(value) }));
		} else {
			setOfferForm((prev) => ({ ...prev, [name]: value }));
		}
	};

	const submitHandler = () => {
		validate(document.getElementsByClassName('needs-validation')[0] as HTMLFormElement).then((validate) => {
			if (validate) {
				if (isEdit) {
					dispatch(editOfferAction({ id: offerForm._id!, formData: offerForm })).then((response: any) => {
						if (response.payload.success) {
							modalClose.current!.click();
						}
					})
				} else {
					dispatch(addOfferAction(offerForm)).then((response: any) => {
						if (response.payload.success) {
							modalClose.current!.click();
						}
					});
				}
			}
		});
	};

	const offerSerachHandler = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target?.value.length > 2) {
			dispatch(
				getOffersAction({
					page: 1,
					perPage: 12,
					orderBy: 'createdAt',
					orderDirection: SORT_DIRECTION.DESC,
					search: e.target.value
				})
			);
		}
	};

	return (
		<div className="container-fluid">
			<WarningComponent title={t("Confirm")} text={t("Delete_Offer_Confirm")} actionHandler={deleteHandler} />
			<div className="row">
				<BreadcrumbComponent breadcrumb={breadcrumb} />
			</div>

			<div className="row">
				<div className="col-lg-12">
					<div>
						<div className="card">
							<div className="card-header border-0">
								<div className="row g-4">
									<div className="col-sm-auto">
										<div>
											<button onClick={() => viewEditHandler(false)} data-bs-toggle="modal" data-bs-target="#editModal" className="btn btn btn-primary">
												<i className="ri-add-line align-bottom me-1"></i> {t('Add')}
											</button>
										</div>
									</div>
									<div className="col-sm">
										<div className="d-flex justify-content-sm-end">
											<div className="search-box ms-2">
												<input type="text" className="form-control bg-light border-0" placeholder={t("Search_Placeholder")} onChange={(e) => offerSerachHandler(e)} />
												<i className="ri-search-line search-icon"></i>
											</div>
										</div>
									</div>
								</div>
							</div>

							{offers.length > 0 ?
								<div className="card-body">
									<div id="table-product-list-all" className="table-card">
										<div className="table-responsive">
											<table className="table align-middle table-nowrap mb-0">
												<thead className="table-light">
													<tr>
														<th scope="col">{t("ID")}</th>
														<th scope="col">{t('Name')}</th>
														<th scope="col">{t('IsActive')}</th>
														<th scope="col">{t('DiscountType')}</th>
														<th scope="col">{t('Actions')}</th>
													</tr>
												</thead>
												<tbody>
													{offers.map((offer: IOffer) =>

														<tr key={offer._id}>
															<td><Link to={'/orders/details/' + offer._id} className="fw-medium">{offer._id}</Link></td>
															<td>{Translation(offer.name)}</td>
															<td>{offer.isActive ? (
																<span className="badge bg-success-subtle text-success text-uppercase">{t('Active')}</span>
															) : (
																<span className="badge bg-danger-subtle text-danger text-uppercase">{t('InActive')}</span>
															)}
															</td>
															<td>{offer.discountType}</td>
															<td>
																<ul className="list-inline hstack gap-2 mb-0">
																	<li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
																		<button
																			onClick={() => viewEditHandler(true, offer)}
																			className="btn btn-link text-primary d-inline-block"
																			data-bs-toggle="modal"
																			data-bs-target="#editModal">
																			<i className="ri-pencil-fill fs-16"></i>
																		</button>
																	</li>
																	<li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Remove">
																		<button
																			className="btn btn-link text-primary d-inline-block"
																			data-bs-toggle="modal"
																			data-bs-target="#warningModal"
																			onClick={() => setDeleteId(offer._id)}>
																			<i className="ri-delete-bin-5-fill fs-16"></i>
																		</button>
																	</li>
																</ul>
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div> : <NoResultsComponent />
							}
						</div>
					</div>
				</div>
			</div>

			<div id="editModal" className="modal fade" tabIndex={-1} aria-labelledby="editModalLabel">
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={modalClose}></button>
						</div>
						<div className="modal-body">
							<div className="row">
								<form className="needs-validation" noValidate>
									<div className="row">
										<div className="col-lg-12">
											<div className="row">
												<div className="col-md-6">
													<div className="mb-3">
														<label className="form-label" htmlFor="nameEn">
															{t('NameEn')}
														</label>
														<input
															type="text"
															className="form-control"
															name="name.En"
															value={offerForm.name?.En || ''}
															onChange={(e) => changeHandler(e)}
															placeholder={t('NameEn')}
															required
														/>
														<div className="invalid-feedback">{t('NameError')}</div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="mb-3">
														<label className="form-label" htmlFor="nameAr">
															{t('NameAr')}
														</label>
														<input
															type="text"
															className="form-control"
															value={offerForm.name?.Ar || ''}
															name="name.Ar"
															onChange={(e) => changeHandler(e)}
															placeholder={t('NameAr')}
															required
														/>
														<div className="invalid-feedback">{t('NameError')}</div>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6">
													<label className="form-label">
														{t('PromoKeys')}
														<TagsInput initialValues={offerForm.promoKeys} tagChangeHandler={changeHandler} name="promoKeys" />
													</label>
												</div>
												<div className="col-lg-6">
													<div className="mb-3">
														<label className="form-label" htmlFor="discountType">
															{t('DiscountType')}
														</label>
														<select className="form-control" value={offerForm.discountType || DISCOUNT_TYPE.FIXED} name="discountType" onChange={changeHandler}>
															<option disabled value="DEFAULT">
																{t('DiscountType')}
															</option>
															{Object.keys(DISCOUNT_TYPE).map((type: any) => (
																<option key={type} value={type}>
																	{t(`${type}`)}
																</option>
															))}
														</select>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6">
													<div className="mb-3">
														<label className="form-label">
															{t('DiscountValue')}
															<input
																type="number"
																className="form-control"
																name="discountValue"
																value={offerForm.discountValue}
																onChange={changeHandler}
																placeholder={t('DiscountValue')}
																required
															/>
														</label>
														<div className="invalid-feedback">{t('DiscountValueError')}</div>
													</div>
												</div>

												<div className="col-md-6">
													<label className="form-label">{t('IsActive')}</label>
													<br />
													<div className="form-check-inline mb-2">
														<label className="form-check-label">
															<input
																className="form-check-input me-1"
																type="radio"
																name="isActive"
																id="true"
																value="true"
																checked={offerForm.isActive}
																onChange={changeHandler}
															/>
															{t('Active')}
														</label>
													</div>
													<div className="form-check-inline mb-2">
														<label className="form-check-label">
															<input
																className="form-check-input me-1"
																type="radio"
																name="isActive"
																id="false"
																value="false"
																checked={!offerForm.isActive}
																onChange={changeHandler}
															/>
															{t('InActive')}
														</label>
													</div>
												</div>
											</div>
											<div className="row col-md-12">
												<div className="text-end">
													<button type="button" className="btn btn-success w-sm" onClick={submitHandler}>
														{t('Add')}
													</button>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
