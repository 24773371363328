import React from 'react';
import { Provider } from 'react-redux';
import RootComponent from './RootComponent';
import { store } from './store/store';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './provider/Auth.provider';
import { ProfileProvider } from './provider/Profile.provider';
import { languageInitialization } from './utility/language';
import { LoadingProvider } from './provider/IsLoading.provider';

const App: React.FC = () => {
	languageInitialization();

	return (
		<BrowserRouter basename="/">
			<Provider store={store}>
				<AuthProvider>
					<ProfileProvider>
						<LoadingProvider>
							<RootComponent />
						</LoadingProvider>
					</ProfileProvider>
				</AuthProvider>
			</Provider>
		</BrowserRouter>
	);
};

export default App;
