import React, { useEffect, useState } from 'react';
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { getAttributesAction } from '../../attribute/actions/attribute.actions';
import { DELIVARY_TYPE } from '../../../utility/enums/delivary-type.enum';
import { DISCOUNT_TYPE } from '../../../utility/enums/discount-type.enum';
import { ICategory } from '../../categories/interface/Category.interface';
import DropzoneComponent from '../../../components/dropzone/Dropzone.component';
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum';
import { useTranslation } from 'react-i18next';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import { Translation } from '../../../utility/helpers/translation.helper';
import { validate } from '../../../utility/helpers/form-validation.helper';
import { addSellerProductAction, uploadGallayImageAction, uploadMainImageAction } from '../actions/product.actions';
import { IAddon, IOptions, IProduct } from '../interface/product.interface';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';
import { getSellerKitchenAction } from '../../kitchen/actions/kitchen.actions';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useLoading } from '../../../provider/IsLoading.provider';

export const SwalAlert = withReactContent(Swal);

const ProductCreatePage: React.FC<any> = () => {
	const { attributes, status: attributeStatus } = useAppSelector((state) => state.attribute);
	const { setLoading } = useLoading();
	const [mainImage, setMainImage] = useState('/assets/images/placeholder.png');
	const [mainPreview, setMainPreview] = useState('/assets/images/placeholder.png');
	const [mainChange, setMainChange] = useState(false);
	const [gallaryImages, setGallaryImages] = useState([]);
	const [gallaryChange, setGallaryChange] = useState(false);
	const [formData, setFormData] = useState({
		delivaryType: DELIVARY_TYPE.SINGLE_ORDER,
		options: [
			{
				price: 0,
				value: '',
				stock: null,
				discountType: DISCOUNT_TYPE.NO_DISCOUNT,
				discountValue: 0
			}
		],
		addons: []
	} as unknown as IProduct);
	const [categories, setCategories] = useState<Array<ICategory>>([]);
	const { kitchen, kitchenStatus } = useAppSelector((state) => state.kitchen);

	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const breadcrumb: Breadcrumb = {
		title: t('Products'),
		links: [
			{ name: t('Dashboard'), path: '/' },
			{ name: t('Products'), path: '/products/list' },
			{ name: t('Create'), path: '/products/create' }
		]
	};

	useEffect(() => {

		if (kitchenStatus === REDUX_STATUS.IDLE) {
			dispatch(getSellerKitchenAction()).then((res: any) => {
				setCategories(res.payload.data.categories);
			});
		}

		if (attributeStatus == REDUX_STATUS.IDLE) {
			dispatch(
				getAttributesAction({
					offset: 1,
					limit: 12,
					order: {
						orderBy: 'createdAt',
						orderDirection: SORT_DIRECTION.ASC
					}
				})
			);
		}
	}, [dispatch, categories, attributeStatus, kitchen, kitchenStatus]);

	const changeHandler = (event: any, index?: number) => {
		const { name, value } = event.target;

		switch (name) {
			case 'price':
			case 'stock':
			case 'discountValue': {
				setFormData((prevFormData) => ({
					...prevFormData,
					options: [
						...prevFormData.options.map((item: IOptions, i: number) => {
							if (i == index) {
								return {
									...item,
									[name]: Number(value)
								};
							} else {
								return item;
							}
						})
					]
				}));
				break;
			}
			case 'value':
			case `discountType${index}`: {
				setFormData((prevFormData) => ({
					...prevFormData,
					options: [
						...prevFormData.options.map((item: IOptions, i: number) => {
							if (i == index) {
								return name == `discountType${index}`
									? {
										...item,
										discountType: value
									}
									: {
										...item,
										[name]: value
									};
							} else {
								return item;
							}
						})
					]
				}));
				break;
			}
			case 'attribute': {
				setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
				break;
			}
			case 'nameEn': {
				setFormData((prevFormData) => ({ ...prevFormData, name: { ...prevFormData.name, En: value } }));
				break;
			}
			case 'nameAr': {
				setFormData((prevFormData) => ({ ...prevFormData, name: { ...prevFormData.name, Ar: value } }));
				break;
			}
			case 'descriptionEn': {
				setFormData((prevFormData) => ({ ...prevFormData, description: { ...prevFormData.description, En: value } }));
				break;
			}
			case 'descriptionAr': {
				setFormData((prevFormData) => ({ ...prevFormData, description: { ...prevFormData.description, Ar: value } }));
				break;
			}
			case `addonNameEn${index}`: {
				setFormData((prevFormData) => ({
					...prevFormData,
					addons: [
						...prevFormData.addons.map((item: any, i: number) => {
							if (i == index) {
								return { ...item, name: { ...item.name, En: value } };
							} else {
								return item;
							}
						})
					]
				}));
				break;
			}
			case `addonNameAr${index}`: {
				setFormData((prevFormData) => ({
					...prevFormData,
					addons: [
						...prevFormData.addons.map((item: any, i: number) => {
							if (i == index) {
								return { ...item, name: { ...item.name, Ar: value } };
							} else {
								return item;
							}
						})
					]
				}));
				break;
			}
			case 'addonPrice' + index: {
				setFormData((prevFormData) => ({
					...prevFormData,
					addons: [
						...prevFormData.addons.map((item: IAddon, i: number) => {
							if (i == index) {
								return { ...item, price: Number(value) };
							} else {
								return item;
							}
						})
					]
				}));
				break;
			}
			case 'isOptional' + index: {
				setFormData((prevFormData) => ({
					...prevFormData,
					addons: [
						...prevFormData.addons.map((item: any, i: number) => {
							if (i == index) {
								return { ...item, isOptional: JSON.parse(value) };
							} else {
								return item;
							}
						})
					]
				}));
				break;
			}
			default: {
				setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
			}
		}
	};

	const addOptionValue = () => {
		setFormData((prevFormData) => ({
			...prevFormData,
			options: [
				...formData.options,
				{
					price: 1,
					value: '',
					stock: null,
					discountType: DISCOUNT_TYPE.NO_DISCOUNT,
					discountValue: 0
				}
			]
		}));
	};

	const addProductAddon = () => {
		setFormData((prevFormData) => ({
			...prevFormData,
			addons: [
				...(formData.addons || []),
				{
					name: {
						En: '',
						Ar: ''
					},
					price: 0,
					isOptional: true
				}
			]
		}));
	};

	const mainUploadHandler = (e: any) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onloadend = () => {
			setMainPreview(reader.result! as string);
		};
		reader.readAsDataURL(file);
		setMainImage(e.target.files[0]);
		setMainChange(true);
	};

	const gallaryUploadHandler = (e: any) => {
		if (e.length > 0) {
			setGallaryImages(e);
			setGallaryChange(true);
		}
	};

	const submitHandler = () => {
		validate(document.getElementsByClassName('needs-validation')[0] as HTMLFormElement).then((validate) => {
			if (validate) {
				setLoading(true);
				dispatch(addSellerProductAction(formData)).then((action: any) => {
					setLoading(false);
					if (action.payload.success) {
						SwalAlert.fire({ position: 'center', icon: 'success', title: action.payload.message });
						if (gallaryChange) {
							const data: FormData = new FormData();
							for (const item in gallaryImages) {
								data.append('files', gallaryImages[item]);
							}
							data.append('fieldName', 'gallary');
							dispatch(uploadGallayImageAction({ id: action.payload.data._id, image: data }));
						}
						if (mainChange) {
							const data = new FormData();
							data.append('file', mainImage);
							data.append('fieldName', 'mainImage');
							dispatch(uploadMainImageAction({ id: action.payload.data._id, image: data }));
						}
					} else {
						SwalAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, html: action.payload.errors.map((err: any) => err.message + '<br />') });
					}
				});
			}
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<BreadcrumbComponent breadcrumb={breadcrumb} />
			</div>

			<form autoComplete="off" className="needs-validation" noValidate>
				<div className="row">
					<div className="col-lg-8">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title mb-0">{t('GeneralInfo')}</h5>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-md-6">
										<div className="mb-3">
											<label className="form-label" htmlFor="product-title-input">
												{t('NameEn')}
											</label>
											<input
												type="text"
												className="form-control"
												id="product-title-input"
												placeholder={t('NameEn')}
												name="nameEn"
												onChange={changeHandler}
												required
											/>
											<div className="invalid-feedback">{t('NameError')}</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="mb-3">
											<label className="form-label" htmlFor="product-title-input">
												{t('NameAr')}
											</label>
											<input
												type="text"
												className="form-control"
												id="product-title-input"
												placeholder={t('NameAr')}
												name="nameAr"
												onChange={changeHandler}
												required
											/>
											<div className="invalid-feedback">{t('NameError')}</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<div className="mb-3">
											<label>{t('ShortDescriptionEn')}</label>
											<textarea
												className="form-control"
												placeholder={t('ShortDescriptionEn')}
												rows={3}
												name="descriptionEn"
												onChange={changeHandler}
												required
											>
											</textarea>
										</div>
									</div>
								</div>
								<div className='row'>
									<div className="col-md-12">
										<div className="mb-3">
											<label>{t('ShortDescriptionAr')}</label>
											<textarea
												className="form-control"
												placeholder={t('ShortDescriptionAr')}
												rows={3}
												name="descriptionAr"
												onChange={changeHandler}
												required
											>
											</textarea>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="mb-2">
											<label className="form-label" htmlFor="product-title-input">
												{t('DelivaryType')}
											</label>
											<div className="mt-4 mt-lg-0">
												<div className="form-check form-check-inline">
													<input
														className="form-check-input"
														type="radio"
														name="delivaryType"
														id="single"
														value={DELIVARY_TYPE.SINGLE_ORDER}
														checked={formData.delivaryType == DELIVARY_TYPE.SINGLE_ORDER}
														onChange={changeHandler}
													/>
													<label className="form-check-label" htmlFor="single">
														{t('SingleOrder')}
													</label>
												</div>
												<div className="form-check form-check-inline">
													<input
														className="form-check-input"
														type="radio"
														name="delivaryType"
														id="group"
														value={DELIVARY_TYPE.GROUP_ORDER}
														checked={formData.delivaryType == DELIVARY_TYPE.GROUP_ORDER}
														onChange={changeHandler}
													/>
													<label className="form-check-label" htmlFor="group">
														{t('GroupOrder')}
													</label>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										{formData.delivaryType == DELIVARY_TYPE.GROUP_ORDER && (
											<>
												<label className="form-label" htmlFor="product-title-input">
													{t('DeliverdDate')}
												</label>
												<Flatpickr
													options={{ minDate: new Date() }}
													className="form-control"
													value={formData.delivaryDate!}
													onChange={([date]: any) => {
														changeHandler({ target: { name: 'delivaryDate', value: date } });
													}}
													required={formData.delivaryType === 'GROUP_ORDER'}
												/>
											</>
										)}
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="mb-2">
											<label className="form-label" htmlFor="attributes">
												{t('Attribute')}
											</label>
											<select
												className="form-select"
												id="attributes"
												name="attribute"
												onChange={changeHandler}
												required>
												<option selected disabled value="">
													{t('Attribute')}
												</option>
												{attributes?.map((attr) => {
													return (
														<option key={attr._id} value={attr._id}>
															{Translation(attr.name)}
														</option>
													);
												})}
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="card">
							<div className="card-header d-flex justify-content-between">
								<ul className="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
									<li className="nav-item">
										<a className="nav-link active" data-bs-toggle="tab" href="#variants" role="tab">
											{t('VariantsInfo')}
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" data-bs-toggle="tab" href="#addons" role="tab">
											{t('Addons')}
										</a>
									</li>
								</ul>
							</div>
							<div className="card-body">
								<div className="tab-content">
									<div className="tab-pane active" id="variants" role="tabpanel">
										{formData.options?.map((_, x: number) => (
											<div key={x} className="border-bottom mb-4 pb-3">
												<div className="row">
													<div className="col-md-6">
														<div className="mb-3">
															<label className="form-label" htmlFor={'value' + x}>
																{t('Value')}
															</label>
															<input
																type="text"
																className="form-control"
																placeholder={t('Value')}
																name="value"
																onChange={(e) => changeHandler(e, x)}
																required
															/>
															<div className="invalid-feedback">{t('ValueError')}</div>
														</div>
													</div>
													<div className="col-md-6">
														<div className="mb-3">
															<label className="form-label" htmlFor={'stock' + x}>
																{t('Stock')}
															</label>
															<input
																type="number"
																className="form-control"
																placeholder={t('Stock')}
																name="stock"
																onChange={(e) => changeHandler(e, x)}
															/>
															<div className="invalid-feedback">{t('StockError')}</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-7">
														<label className="form-label">{t('DiscountType')}</label>
														<div className="mt-4 mt-lg-0">
															<div className="form-check form-check-inline">
																<label className="form-check-label">
																	<input
																		className="form-check-input"
																		type="radio"
																		name={'discountType' + x}
																		value={DISCOUNT_TYPE.NO_DISCOUNT}
																		checked={formData.options[x].discountType == DISCOUNT_TYPE.NO_DISCOUNT}
																		onChange={(e) => changeHandler(e, x)}
																	/>
																	{t('NO_DISCOUNT')}
																</label>
															</div>
															<div className="form-check form-check-inline">
																<label className="form-check-label">
																	<input
																		className="form-check-input"
																		type="radio"
																		name={'discountType' + x}
																		value={DISCOUNT_TYPE.FIXED}
																		checked={formData.options[x].discountType == DISCOUNT_TYPE.FIXED}
																		onChange={(e) => changeHandler(e, x)}
																	/>
																	{t('Fixed')}
																</label>
															</div>
															<div className="form-check form-check-inline">
																<label className="form-check-label">
																	<input
																		className="form-check-input"
																		type="radio"
																		name={'discountType' + x}
																		value={DISCOUNT_TYPE.PERCENT}
																		checked={formData.options[x].discountType == DISCOUNT_TYPE.PERCENT}
																		onChange={(e) => changeHandler(e, x)}
																	/>
																	{t('Percent')}
																</label>
															</div>
														</div>
													</div>
													{formData.options[x].discountType != DISCOUNT_TYPE.NO_DISCOUNT && (
														<div className="col-md-5">
															<div className="mb-3">
																<label className="form-label" htmlFor={'discountValue' + x}>
																	{t('Discount')}
																</label>
																<div className="input-group mb-3">
																	<span className="input-group-text" id={'discountValue' + x}>
																		{formData.options[x].discountType == DISCOUNT_TYPE.FIXED ? t('CurrencyCode') : '%'}
																	</span>
																	<input
																		type="number"
																		className="form-control"
																		name="discountValue"
																		onChange={(e) => changeHandler(e, x)}
																		placeholder={t('Discount')}
																		required
																	/>
																</div>
															</div>
														</div>
													)}
												</div>
												<div className="row">
													<div className="col-md-6">
														<div className="mb-3">
															<label className="form-label" htmlFor={'price' + x}>
																{t('Price')}
															</label>
															<div className="input-group has-validation mb-3">
																<span className="input-group-text" id={'price' + x}>
																	{t('CurrencyCode')}
																</span>
																<input
																	type="number"
																	className="form-control"
																	id={'price' + x}
																	placeholder={t('Price')}
																	name="price"
																	onChange={(e) => changeHandler(e, x)}
																	required
																/>
																<div className="invalid-feedback">{t('PriceError')}</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										))}
										<button type="button" id="add-item" className="btn btn-soft-secondary fw-medium ml-auto" onClick={addOptionValue}>
											<i className="ri-add-fill me-1 align-bottom"></i>
										</button>
									</div>
									<div className="tab-pane" id="addons" role="tabpanel">
										{formData.addons?.map((val: IAddon, x: number) => (
											<div key={x} className="border-bottom mb-4 pb-3">
												<div className="row">
													<div className="col-md-6">
														<div className="mb-3">
															<label className="form-label" htmlFor={'addonNameEn' + x}>
																{t('NameEn')}
															</label>
															<input
																type="text"
																className="form-control"
																placeholder={t('NameEn')}
																name={'addonNameEn' + x}
																onChange={(e) => changeHandler(e, x)}
																required
															/>
															<div className="invalid-feedback">{t('NameError')}</div>
														</div>
													</div>
													<div className="col-md-6">
														<div className="mb-3">
															<label className="form-label" htmlFor={'addonNameAr' + x}>
																{t('NameAr')}
															</label>
															<input
																type="text"
																className="form-control"
																placeholder={t('NameAr')}
																name={'addonNameAr' + x}
																onChange={(e) => changeHandler(e, x)}
																required
															/>
															<div className="invalid-feedback">{t('NameError')}</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<label className="form-label">{t('IsOptional')}</label>
														<div className="mt-4 mt-lg-0">
															<div className="form-check form-check-inline">
																<label className="form-check-label">
																	<input
																		className="form-check-input"
																		type="radio"
																		name={'isOptional' + x}
																		value="true"
																		checked={formData.addons[x].isOptional == true}
																		onChange={(e) => changeHandler(e, x)}
																	/>
																	{t('Optional')}
																</label>
															</div>

															<div className="form-check form-check-inline">
																<label className="form-check-label">
																	<input
																		className="form-check-input"
																		type="radio"
																		name={'isOptional' + x}
																		value="false"
																		checked={formData.addons[x].isOptional == false}
																		onChange={(e) => changeHandler(e, x)}
																	/>
																	{t('Required')}
																</label>
															</div>
														</div>
													</div>
													<div className="col-md-6">
														<div className="mb-3">
															<label className="form-label" htmlFor={'addonPrice' + x}>
																{t('Price')}
															</label>
															<div className="input-group has-validation mb-3">
																<span className="input-group-text" id={'addonPrice' + x}>
																	{t('CurrencyCode')}
																</span>
																<input
																	type="number"
																	className="form-control"
																	placeholder={t('Price')}
																	name={'addonPrice' + x}
																	onChange={(e) => changeHandler(e, x)}
																	required
																/>
																<div className="invalid-feedback">{t('PriceError')}</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										))}
										<button type="button" id="add-item" className="btn btn-soft-secondary fw-medium" onClick={addProductAddon}>
											<i className="ri-add-fill me-1 align-bottom"></i>
										</button>
									</div>
								</div>
							</div>
						</div>

						<div className="card">
							<div className="card-header">
								<h5 className="card-title mb-0">{t('ProductGallery')}</h5>
							</div>
							<div className="card-body">
								<DropzoneComponent uploadedFiles={gallaryUploadHandler}></DropzoneComponent>
							</div>
						</div>
					</div>

					<div className="col-lg-4">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title mb-0">{t('MainImage')}</h5>
							</div>
							<div className="card-body">
								<div className="mb-4">
									<div className="text-center">
										<div className="position-relative d-inline-block">
											<div className="position-absolute top-100 start-100 translate-middle">
												<label htmlFor="logo-image" className="mb-0" data-bs-toggle="tooltip" data-bs-placement="right" title="Select Logo">
													<div className="avatar-xs">
														<div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
															<i className="ri-image-fill"></i>
														</div>
													</div>
												</label>
												<input
													className="form-control d-none"
													id="logo-image"
													type="file"
													accept="image/png, image/gif, image/jpeg"
													onChange={mainUploadHandler}
												/>
											</div>
											<div className="avatar-lg">
												<div className="avatar-title bg-light rounded">
													<img
														className="avatar-xl h-auto"
														src={mainPreview}
														alt="Product-Image"
														onLoad={() => {
															URL.revokeObjectURL(mainPreview);
														}}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="card">
							<div className="card-header">
								<h5 className="card-title mb-0">{t('Category')}</h5>
							</div>
							<div className="card-body">
								<select className="form-select" id="category" name="category" onChange={changeHandler} required>
								<option selected disabled value="">
										{t('Category')}
									</option>
									{categories.length && (categories?.map((category: ICategory) => (
										<option key={category._id} value={category._id}>
											{Translation(category.name)}
										</option>
									)))}
								</select>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-8">
						<div className="text-start mb-3">
							<button type="button" className="btn btn-success w-sm" onClick={submitHandler}>
								{t('Add')}
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default ProductCreatePage;
