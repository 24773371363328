import React, { ChangeEvent, useEffect } from 'react';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { getAdminKitchenAction, getSellerKitchenAction } from '../actions/kitchen.actions';
import { useProfile } from '../../../provider/Profile.provider';
import { Translation } from '../../../utility/helpers/translation.helper';
import { GetYear } from '../../../utility/helpers/date.helper';
import { ArrayFromNumber } from '../../../utility/helpers/array-from-number.helper';
import { ROLE } from '../../../utility/enums/role.enum';
import { listAdminProductsAction, listSellerProductsAction } from '../../products/actions/product.actions';
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';
import { useLoading } from '../../../provider/IsLoading.provider';
import { IProduct } from '../../products/interface/product.interface';
import { NoResultsComponent } from '../../../components/no-results/NoResults.component';

const KitchenViewPage: React.FC<any> = () => {
	const assets = process.env.REACT_APP_ASSETS_URL;
	const { t } = useTranslation();
	const params = useParams();
	const dispatch = useAppDispatch();
	const { setLoading } = useLoading();
	const userProfile = useProfile();
	const { products, status: productStatus } = useAppSelector((state) => state.product);
	const { kitchen, kitchenStatus } = useAppSelector((state) => state.kitchen);
	const breadcrumb: Breadcrumb = {
		title: t('MyKitchen'),
		links: [
			{ name: t('Dashboard'), path: '/' },
			{ name: t('MyKitchen'), path: '/kitchens/view/' + params.id }
		]
	};

	useEffect(() => {
		if (kitchenStatus == (REDUX_STATUS.SUCCEEDED || REDUX_STATUS.FAILED)) {
			setLoading(false);
		}
	}, [setLoading, kitchenStatus]);

	useEffect(() => {
		if (kitchenStatus == REDUX_STATUS.IDLE) {
			setLoading(true);
			if (userProfile.role?.type == ROLE.ADMIN) {
				dispatch(getAdminKitchenAction(params.id as string));
			} else {
				dispatch(getSellerKitchenAction());
			}
		}
	}, [dispatch, kitchenStatus, params, setLoading, userProfile]);

	useEffect(() => {
		if (productStatus == REDUX_STATUS.IDLE) {
			if (userProfile.role?.type == ROLE.ADMIN) {
				dispatch(
					listAdminProductsAction({
						page: 1,
						perPage: 12,
						orderBy: 'createdAt',
						orderDirection: SORT_DIRECTION.ASC,
						kitchen: kitchen._id
					})
				);
			} else {
				dispatch(
					listSellerProductsAction({
						page: 1,
						perPage: 12,
						orderBy: 'createdAt',
						orderDirection: SORT_DIRECTION.ASC
					})
				);
			}
		}
	}, [dispatch, kitchen, productStatus, userProfile]);

	const productSerachHandler = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target?.value.length > 2) {
			if (userProfile.role?.type == ROLE.ADMIN) {
				dispatch(
					listAdminProductsAction({
						page: 1,
						perPage: 12,
						orderBy: 'createdAt',
						orderDirection: SORT_DIRECTION.ASC,
						search: e.target?.value
					})
				);
			} else {
				dispatch(
					listSellerProductsAction({
						page: 1,
						perPage: 12,
						orderBy: 'createdAt',
						orderDirection: SORT_DIRECTION.ASC,
						search: e.target?.value
					})
				);
			}
		}
	};

	return (
		<div className="container-fluid">
			<BreadcrumbComponent breadcrumb={breadcrumb} />

			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body p-4">
							{userProfile.role?.type == ROLE.SELLER && (
								<div>
									<Link to={'/kitchens/edit/' + kitchen._id} className="btn btn-primary">
										<i className="ri-pencil-fill align-bottom me-1"></i> {t('Edit')}
									</Link>
								</div>
							)}
							<div className="banner-image">
								<img src={assets + kitchen.banner} alt={kitchen.name?.En} height="50" />
							</div>
							<div className="mt-4 text-center">
								<div className="d-flex justify-content-center">
									<div className="mx-2">
										<img src={assets + kitchen.logo} alt={kitchen.name?.En} height="50" />
									</div>
									<div>
										<h5 className="mb-1">{Translation(kitchen.name)}</h5>
										<p className="text-muted">
											{t('Since')} {GetYear(kitchen.createdAt)}
										</p>
									</div>
								</div>
							</div>
							<div className="table-responsive">
								<table className="table mb-0 table-borderless">
									<tbody>
										<tr>
											<th>
												<span className="fw-medium">{t('OwnerName')}</span>
											</th>
											<td>{kitchen.owner?.firstName + ' ' + kitchen.owner?.lastName}</td>
										</tr>
										<tr>
											<th>
												<span className="fw-medium">{t('EmailAddress')}</span>
											</th>
											<td>{kitchen.owner?.email}</td>
										</tr>
										<tr>
											<th>
												<span className="fw-medium">{t('MobileNumber')}</span>
											</th>
											<td>{kitchen.owner?.mobileNumber}</td>
										</tr>
										<tr>
											<th>
												<span className="fw-medium">{t('Location')}</span>
											</th>
											<td>{Translation(kitchen.governorate?.name)}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div className="card-body border-top border-top-dashed p-4">
							<div>
								<h6 className="text-muted text-uppercase fw-semibold mb-4">{t('CustomerReviews')}</h6>
								<div>
									<div className="bg-light px-3 py-2 rounded-2 mb-2">
										<div className="d-flex align-items-center">
											<div className="flex-grow-1">
												{kitchen.rating && (
													<div className="fs-16 align-middle text-warning">
														{ArrayFromNumber(kitchen.rating).map((item: any) => (
															<i key={item} className="ri-star-fill"></i>
														))}
														{ArrayFromNumber(5 - kitchen.rating).map((item: any) => (
															<i key={item} className="ri-star-line"></i>
														))}
													</div>
												)}
											</div>
											<div className="flex-shrink-0">
												<h6 className="mb-0">{kitchen.rating} / 5</h6>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-header border-0">
							<div className="row g-4">
								{userProfile.role?.type == ROLE.SELLER && (
									<div className="col-sm-auto">
										<div>
											<Link to={'/products/create'} className="btn btn-primary" id="addproduct-btn">
												<i className="ri-add-line align-bottom me-1"></i> {t("AddNew")}
											</Link>
										</div>
									</div>
								)}
								<div className="col-sm">
									<div className="d-flex justify-content-sm-end">
										<div className="search-box ms-2">
											<input type="text" className="form-control bg-light border-0" placeholder={t("Search_Placeholder")} onChange={(e) => productSerachHandler(e)} />
											<i className="ri-search-line search-icon"></i>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="card-body">
							{products.length > 0 ?
								<div id="table-product-list-all" className="table-card">
									<div className="table-responsive">
										<table className="table align-middle table-nowrap mb-0">
											<thead className="table-light">
												<tr>
													<th scope="col">{t("Name")}</th>
													<th scope="col">{t('Category')}</th>
													<th scope="col">{t('DelivaryType')}</th>
													<th scope="col">{t('IsActive')}</th>
													<th scope="col">{t('Rating')}</th>
													<th scope="col">{t('Actions')}</th>
												</tr>
											</thead>
											<tbody>
												{products.map((product: IProduct) =>

													<tr key={product._id}>
														<td>
															<div className="d-flex align-items-center">
																<div className="flex-shrink-0 mx-2">
																	<div className="avatar-sm bg-light rounded p-1">
																		<img src={assets + product.mainImage} alt={product.name?.En} className="img-fluid d-block" />
																	</div>
																</div>
																<div className="flex-grow-1">
																	<h5 className="fs-14 mb-1">
																		<Link to={'/products/view/' + product._id} className="text-body">
																			{Translation(product.name)}
																		</Link>
																	</h5>
																	<p className="text-muted mb-0">
																		{t('Category')} : <span className="fw-medium">{Translation(product.category?.name)}</span>
																	</p>
																</div>
															</div>
														</td>
														<td>{Translation(product.category?.name)}</td>
														<td>{t(product.delivaryType)}</td>
														<td>{product.isActive ? (
															<span className="badge bg-success-subtle text-success text-uppercase">{t('Active')}</span>
														) : (
															<span className="badge bg-danger-subtle text-danger text-uppercase">{t('InActive')}</span>
														)}
														</td>
														<td><span className="badge bg-light text-body fs-12 fw-medium"><i className="mdi mdi-star text-warning me-1"></i> ${product.rating} </span></td>
														<td>
															<ul className="list-inline hstack gap-2 mb-0">
																<li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title={t("View")}>
																	<Link to={'/products/view/' + product._id} className="text-body">
																		<i className="ri-eye-fill fs-16"></i>
																	</Link>
																</li>
																<li className="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title={t("Edit")}>
																	<Link to={'/products/edit/' + product._id} className="text-primary d-inline-block edit-item-btn" role="button">
																		<i className="ri-pencil-fill fs-16"></i>
																	</Link>
																</li>
															</ul>
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
								</div> : <NoResultsComponent />
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default KitchenViewPage;
