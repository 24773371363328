import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from '../i18n/en.json'
import ar from '../i18n/ar.json'

const resources = {
  en: {
    translation: en
  },
  ar: {
    translation: ar
  }
}
const language = window.localStorage.getItem('language') || 'ar'

export const languageInitialization = () => {
	const link: HTMLLinkElement = document.querySelector('[id="rtlStyle"]')!;
	const href = "/assets/css/app-rtl.min.css";
	link.href = language === 'ar' ? href : '';
	
	window.document.getElementsByTagName('html')[0].setAttribute('lang', language)
	window.document.getElementsByTagName('html')[0].setAttribute('dir', language === 'en' ? 'ltr' : 'rtl')
	
  i18n.use(initReactI18next).init({
    resources: resources,
    supportedLngs: ['en', 'ar'],
    fallbackLng: language ?? process.env.REACT_APP_DEFAULT_LANGUAGE,
    debug: false,
    react: { useSuspense: false }
  })
}
