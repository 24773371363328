import React, { useEffect, useRef, useState } from 'react';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { addAttributeAction, editAttributeAction, getAttributesAction } from '../actions/attribute.actions';
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component';
import { useTranslation } from 'react-i18next';
import { validate } from '../../../utility/helpers/form-validation.helper';
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum';
import { IAttribute } from '../interface/attribute.interface';
import { NoResultsComponent } from '../../../components/no-results/NoResults.component';

const AttributesListPage: React.FC = () => {
	const breadcrumb: Breadcrumb = {
		title: 'Attributes',
		links: [
			{ name: 'Dashboard', path: '/' },
			{ name: 'Attributes', path: '/attributes/list' }
		]
	};
	const dispatch = useAppDispatch();
	const { attributes } = useAppSelector((state) => state.attribute);
	const [attributeForm, setAttributeForm] = useState({ nameEn: '', nameAr: '', unit: 'PICE', _id: null });
	const { t } = useTranslation();
	const [units] = useState(['PICE', 'GRAM', 'KILOGRAM', 'LITRE', 'CENTIMETER']);
	const [isEdit, setIsEdit] = useState(false);
	const modalClose = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (!attributes) {
			dispatch(
				getAttributesAction({
					offset: 1,
					limit: 12,
					order: {
						orderBy: 'createdAt',
						orderDirection: SORT_DIRECTION.ASC
					}
				})
			);
		}
	}, [dispatch, attributes]);

	const viewEditHandler = async (edit: boolean, e?: any) => {
		if (edit) {
			setIsEdit(true);
			await setAttributeForm({ nameEn: e.name.En, nameAr: e.name.Ar, unit: 'PICE', _id: e._id });
			setIsEdit(true);
		} else {
			setIsEdit(false);
			await setAttributeForm({ nameEn: '', nameAr: '', unit: 'PICE', _id: null });
		}
	};

	const changeHandler = (event: any) => {
		const { name, value } = event.target;
		setAttributeForm((prevFormData) => ({ ...prevFormData, [name]: value }));
	};

	const submitHandler = () => {
		validate(document.getElementsByClassName('needs-validation')[0] as HTMLFormElement).then((validate) => {
			if (validate) {
				const postData = {
					name: {
						En: attributeForm.nameEn,
						Ar: attributeForm.nameAr
					},
					unit: attributeForm.unit
				};
				if (isEdit) {
					dispatch(editAttributeAction({ id: attributeForm._id!, formData: postData })).then((res: any) => {
						if (res.payload.success) {
							modalClose.current!.click();
						}
					});
				} else {
					dispatch(addAttributeAction(postData)).then((res: any) => {
						if (res.payload.success) {
							modalClose.current!.click();
						}
					});
				}
			}
		});
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<BreadcrumbComponent breadcrumb={breadcrumb} />
			</div>

			<div className="row">
				<div className="col-lg-12">
					<div>
						<div className="card">
							<div className="card-header border-0">
								<div className="row g-4">
									<div className="col-sm-auto">
										<div>
											<button onClick={() => viewEditHandler(false)} data-bs-toggle="modal" data-bs-target="#editModal" className="btn btn-primary">
												<i className="ri-add-line align-bottom me-1"></i> {t('Add')}
											</button>
										</div>
									</div>
									<div className="col-sm">
										<div className="d-flex justify-content-sm-end">
											<div className="search-box ms-2">
												<input type="text" className="form-control" id="searchProductList" placeholder="Search ..." />
												<i className="ri-search-line search-icon"></i>
											</div>
										</div>
									</div>
								</div>
							</div>

							{attributes!.length > 0 && <div className="card-body">
								<div id="table-product-list-all" className="table-card">
									<div className="table-responsive">
										<table className="table align-middle table-nowrap mb-0">
											<thead className="table-light">
												<tr>
													<th scope="col">{t("ID")}</th>
													<th scope="col">{t('NameAr')}</th>
													<th scope="col">{t('NameEn')}</th>
													<th scope="col">{t('UnitOfMeasure')}</th>
													<th scope="col">{t('Actions')}</th>
												</tr>
											</thead>
											<tbody>
												{attributes!.map((attribute: IAttribute) =>

													<tr key={attribute._id}>
														<td>
															<div className="d-flex align-items-center">
																<div className="flex-grow-1">
																	<p className="text-muted mb-0">{attribute._id}</p>
																</div>
															</div>
														</td>
														<td>{attribute.name.Ar}</td>
														<td>{attribute.name.En}</td>
														<td>{attribute.unit}</td>
														<td>
															<ul className="list-inline hstack gap-2 mb-0">
																<li className="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
																	<button
																		onClick={() => viewEditHandler(true, attribute)}
																		className="btn btn-link text-primary d-inline-block"
																		data-bs-toggle="modal"
																		data-bs-target="#editModal">
																		<i className="ri-pencil-fill fs-16"></i>
																	</button>
																</li>
															</ul>
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
								</div>
							</div>}
							{!attributes!.length && <NoResultsComponent />}
						</div>
					</div>
				</div>
			</div>

			<div id="editModal" className="modal fade" tabIndex={-1} aria-labelledby="editModalLabel">
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={modalClose}></button>
						</div>
						<div className="modal-body">
							<div className="row">
								<form className="needs-validation" onSubmit={submitHandler} noValidate>
									<div className="row">
										<div className="col-lg-12">
											<div className="row">
												<div className="col-md-6">
													<div className="mb-3">
														<label className="form-label" htmlFor="nameEn">
															{t('NameEn')}
														</label>
														<input
															type="text"
															className="form-control"
															id="nameEn"
															name="nameEn"
															value={attributeForm.nameEn}
															onChange={(e) => changeHandler(e)}
															placeholder={t('NameEn')}
															required
														/>
														<div className="invalid-feedback">{t('NameError')}</div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="mb-3">
														<label className="form-label" htmlFor="nameAr">
															{t('NameAr')}
														</label>
														<input
															type="text"
															className="form-control"
															id="nameAr"
															value={attributeForm.nameAr}
															name="nameAr"
															onChange={(e) => changeHandler(e)}
															placeholder={t('NameAr')}
															required
														/>
														<div className="invalid-feedback">{t('NameError')}</div>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-lg-6">
													<div className="mb-3">
														<label className="form-label" htmlFor="unit">
															{t('UnitOfMeasure')}
														</label>

														<select
															className="form-select"
															defaultValue={'DEFAULT'}
															value={attributeForm.unit}
															id="unit"
															name="unit"
															onChange={(e) => changeHandler(e)}>
															<option disabled value="DEFAULT">
																{t('UnitOfMeasure')}
															</option>
															{units.map((unit: any) => (
																<option key={unit} value={unit}>
																	{unit}
																</option>
															))}
														</select>
													</div>
												</div>
											</div>
											<div className="row col-md-12">
												<div className="text-end">
													<button type="button" className="btn btn-success w-sm" onClick={submitHandler}>
														{t('Add')}
													</button>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AttributesListPage;
