import { createAsyncThunk } from '@reduxjs/toolkit'
import { createFirebaseTokenService, loginService, refreshTokenService, signupService, updateFirebaseTokenService } from '../auth.service'

export const loginAction = createAsyncThunk('loginAction/post', async (data: any, thunkApi) => {
	try {
		const response = await loginService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const signupAction = createAsyncThunk('signupAction/post', async (data: any, thunkApi) => {
	try {
		const response = await signupService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const refreshTokenAction = createAsyncThunk('refreshTokenAction/post', async (_, thunkApi) => {
	try {
		const response = await refreshTokenService()
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const createFirebaseTokenAction = createAsyncThunk('createFirebaseTokenAction/post', async (token: string, thunkApi) => {
	try {
		const response = await createFirebaseTokenService(token)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const updateFirebaseTokenAction = createAsyncThunk('updateFirebaseTokenAction/post', async (token: string, thunkApi) => {
	try {
		const response = await updateFirebaseTokenService(token)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})
