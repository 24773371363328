import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum';
import { listSellerClosedPaymentsAction, listSellerPendingPaymentsAction, listSellerProcessingPaymentsAction, requestSellerPaymentsAction } from '../actions/payments.actions';
import { useLoading } from '../../../provider/IsLoading.provider';
import { IPayment } from '../interfaces/payments.interface';
import { Link, useNavigate } from 'react-router-dom';
import WarningComponent from '../../../components/Confirmation-modals/warning.component';
import { NoResultsComponent } from '../../../components/no-results/NoResults.component';
import { PAYMENT_STATUS } from '../../../utility/enums/payment-status.enum';

export const PaymentsListPage: React.FC<any> = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { pendingPayments, processingPayments, closedPayments } = useAppSelector((state) => state.payment);
	const { setLoading } = useLoading();
	const navigate = useNavigate();

	const [totalEarning, setTotalEarning] = useState(0);
	const [remainingBalance, setRemainingBalance] = useState(0);
	const [paidBalance, setPaidBalance] = useState(0);

	const breadcrumb: Breadcrumb = {
		title: t('Payments'),
		links: [
			{ name: t('Dashboard'), path: '/' },
			{ name: t('Payments'), path: '/payments/list' }
		]
	};

	useEffect(() => {
		setLoading(true);
		dispatch(
			listSellerPendingPaymentsAction({
				page: 1,
				perPage: 12,
				orderBy: 'createdAt',
				orderDirection: SORT_DIRECTION.DESC,
				status: PAYMENT_STATUS.PENDING
			})
		);
		dispatch(
			listSellerProcessingPaymentsAction({
				page: 1,
				perPage: 12,
				orderBy: 'createdAt',
				orderDirection: SORT_DIRECTION.DESC,
				status: PAYMENT_STATUS.PROCESSING
			})
		);
		dispatch(
			listSellerClosedPaymentsAction({
				page: 1,
				perPage: 12,
				orderBy: 'createdAt',
				orderDirection: SORT_DIRECTION.DESC,
				status: PAYMENT_STATUS.CLOSED
			})
		);

		setLoading(false);
	}, [dispatch, setLoading]);

	useEffect(() => {
		calcTotalOrders(pendingPayments, processingPayments, closedPayments);
		calcRemainingBalance(pendingPayments);
		calcPaidBalance(pendingPayments);
	}, [pendingPayments, processingPayments, closedPayments, setLoading]);

	const calcTotalOrders = async (pendingPayments: Array<IPayment>, processingPayments: Array<IPayment>, closedPayments: Array<IPayment>) => {
		let pendingTotal = 0;
		let processingTotal = 0;
		let closedTotal = 0;

		await pendingPayments.map((bill: IPayment) => {
			pendingTotal += bill.total;
		});
		await processingPayments.map((bill: IPayment) => {
			processingTotal += bill.total;
		});
		await closedPayments.map((bill: IPayment) => {
			closedTotal += bill.total;
		});
		setTotalEarning(pendingTotal + processingTotal + closedTotal);
	};

	const calcRemainingBalance = (pendingPayments: Array<IPayment>) => {
		let remaining = 0;
		pendingPayments.map((bill: IPayment) => {
			remaining += (bill.paidOnline - bill.serviceFee);
		});
		setRemainingBalance(remaining);
	};

	const calcPaidBalance = (pendingPayments: Array<IPayment>) => {
		let paid = 0;
		pendingPayments.map((bill: IPayment) => {
			paid += bill.paidCash;
		});
		setPaidBalance(paid);
	};

	const withdrawHandler = () => {
		dispatch(requestSellerPaymentsAction()).then(() => navigate('/payments/list'));
	};

	const paymentSerachHandler = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target?.value.length > 2) {
			dispatch(
				listSellerPendingPaymentsAction({
					page: 1,
					perPage: 12,
					orderBy: 'createdAt',
					orderDirection: SORT_DIRECTION.DESC,
					search: e.target.value
				})
			);
		}
	};

	return (
		<div className="container-fluid">
			<WarningComponent actionHandler={withdrawHandler} title={t("Confirm")} text={t("Confirm_Withdraw")} />
			<div className="row">
				<BreadcrumbComponent breadcrumb={breadcrumb} />
			</div>

			<div className="row">
				<div className="col-xl-3 col-md-6">
					<div className="card card-animate">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className="flex-grow-1 overflow-hidden">
									<p className="text-uppercase fw-medium text-muted text-truncate mb-0"> {t("Total_Earnings")}</p>
								</div>
								<div className="flex-shrink-0">
									<span className="avatar-title bg-success-subtle rounded fs-3">
										<i className="bx bx-dollar-circle text-success"></i>
									</span>
								</div>
							</div>
							<div className="d-flex align-items-end justify-content-between mt-4">
								<div>
									<h4 className="fs-22 fw-semibold ff-secondary">
										<span className="counter-value" data-target={totalEarning}>
											{totalEarning} {t('CurrencyCode')}
										</span>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6">
					<div className="card card-animate">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className="flex-grow-1 overflow-hidden">
									<p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('Bills')}</p>
								</div>
								<div className="flex-shrink-0">
									<span className="avatar-title bg-info-subtle rounded fs-3">
										<i className="bx bx-shopping-bag text-info"></i>
									</span>
								</div>
							</div>
							<div className="d-flex align-items-end justify-content-between mt-4">
								<div>
									<h4 className="fs-22 fw-semibold ff-secondary">
										<span className="counter-value" data-target={pendingPayments.length}>
											{pendingPayments.length} {t('Orders')}
										</span>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6">
					<div className="card card-animate">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className="flex-grow-1 overflow-hidden">
									<p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t("Paid_Balance")}</p>
								</div>
								<div className="flex-shrink-0">
									<span className="avatar-title bg-warning-subtle rounded fs-3">
										<i className="bx bx-user-circle text-warning"></i>
									</span>
								</div>
							</div>
							<div className="d-flex align-items-end justify-content-between mt-4">
								<div>
									<h4 className="fs-22 fw-semibold ff-secondary">
										<span className="counter-value" data-target={paidBalance}>
											{paidBalance} {t('CurrencyCode')}
										</span>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6">
					<div className="card card-animate">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className="flex-grow-1 overflow-hidden">
									<p className="text-uppercase fw-medium text-muted text-truncate mb-0"> {t("Remaining_Balance")}</p>
								</div>
								<div className="flex-shrink-0">
									<span className="avatar-title bg-primary-subtle rounded fs-3">
										<i className="bx bx-wallet text-primary"></i>
									</span>
								</div>
							</div>
							<div className="d-flex align-items-end justify-content-between mt-4">
								<h4 className="fs-22 fw-semibold ff-secondary d-flex justify-content-between">
									<span className="counter-value" data-target={remainingBalance}>
										{remainingBalance} {t('CurrencyCode')}
									</span>
								</h4>
								{remainingBalance > 1000 &&
									<button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#warningModal">{t("Withdraw")}</button>
								}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-12">
					<div>
						<div className="card">
							<div className="card-header border-0">
								<div className="row g-4">
									<div className="col-sm">
										<div className="d-flex justify-content-sm-end">
											<div className="search-box ms-2">
												<input type="text" className="form-control bg-light border-0" placeholder={t("Search_Placeholder")} onChange={(e) => paymentSerachHandler(e)} />
												<i className="ri-search-line search-icon"></i>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col"><ul className="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
										<li className="nav-item">
											<a className="nav-link active" data-bs-toggle="tab" href="#pending" role="tab">
												{t('Pending')}
											</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" data-bs-toggle="tab" href="#processing" role="tab">
												{t('Processing')}
											</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" data-bs-toggle="tab" href="#closed" role="tab">
												{t('Closed')}
											</a>
										</li>
									</ul>
									</div>
								</div>
							</div>

							<div className="card-body">

								<div className="tab-content">
									<div className="tab-pane active" id="pending" role="tabpanel">
										{pendingPayments.length > 0 ?
											<div id="table-product-list-all" className="table-card">
												<div className="table-responsive">
													<table className="table align-middle table-nowrap mb-0">
														<thead className="table-light">
															<tr>
																<th scope="col">{t("OrderId")}</th>
																<th scope="col">{t('PaymentMethod')}</th>
																<th scope="col">{t('IsActive')}</th>
																<th scope="col">{t('ServiceFee')}</th>
																<th scope="col">{t('Total')}</th>
																<th scope="col">{t('Status')}</th>
															</tr>
														</thead>
														<tbody>
															{pendingPayments.map((payment: IPayment) =>

																<tr key={payment._id}>
																	<td>
																		<div className="d-flex align-items-center">
																			<div className="flex-grow-1">
																				<p className="text-muted mb-0">
																					<Link to={'/orders/details/' + payment.order} className="text-body">
																						{payment.order}
																					</Link>
																				</p>
																			</div>
																		</div>
																	</td>
																	<td>{t(payment.paymentMethod)}</td>
																	<td>{payment.isActive ? (
																		<span className="badge bg-success-subtle text-success text-uppercase">{t('Active')}</span>
																	) : (
																		<span className="badge bg-danger-subtle text-danger text-uppercase">{t('InActive')}</span>
																	)}
																	</td>
																	<td>{payment.serviceFee + " " + t('CurrencyCode')}</td>
																	<td>{payment.total + " " + t('CurrencyCode')}</td>
																	<td>{t(payment.status)}</td>
																</tr>
															)}
														</tbody>
													</table>
												</div>
											</div> : <NoResultsComponent />
										}
									</div>
									<div className="tab-pane" id="processing" role="tabpanel">
										{processingPayments.length > 0 ?
											<div id="table-product-list-all" className="table-card">
												<div className="table-responsive">
													<table className="table align-middle table-nowrap mb-0">
														<thead className="table-light">
															<tr>
																<th scope="col">{t("OrderId")}</th>
																<th scope="col">{t('PaymentMethod')}</th>
																<th scope="col">{t('IsActive')}</th>
																<th scope="col">{t('ServiceFee')}</th>
																<th scope="col">{t('Total')}</th>
																<th scope="col">{t('Status')}</th>
															</tr>
														</thead>
														<tbody>
															{processingPayments.map((payment: IPayment) =>

																<tr key={payment._id}>
																	<td>
																		<div className="d-flex align-items-center">
																			<div className="flex-grow-1">
																				<p className="text-muted mb-0">
																					<Link to={'/orders/details/' + payment.order} className="text-body">
																						{payment.order}
																					</Link>
																				</p>
																			</div>
																		</div>
																	</td>
																	<td>{t(payment.paymentMethod)}</td>
																	<td>{payment.isActive ? (
																		<span className="badge bg-success-subtle text-success text-uppercase">{t('Active')}</span>
																	) : (
																		<span className="badge bg-danger-subtle text-danger text-uppercase">{t('InActive')}</span>
																	)}
																	</td>
																	<td>{payment.serviceFee + " " + t('CurrencyCode')}</td>
																	<td>{payment.total + " " + t('CurrencyCode')}</td>
																	<td>{t(payment.status)}</td>
																</tr>
															)}
														</tbody>
													</table>
												</div>
											</div> : <NoResultsComponent />
										}
									</div>
									<div className="tab-pane" id="closed" role="tabpanel">
										{closedPayments.length > 0 ?
											<div id="table-product-list-all" className="table-card">
												<div className="table-responsive">
													<table className="table align-middle table-nowrap mb-0">
														<thead className="table-light">
															<tr>
																<th scope="col">{t("OrderId")}</th>
																<th scope="col">{t('PaymentMethod')}</th>
																<th scope="col">{t('IsActive')}</th>
																<th scope="col">{t('ServiceFee')}</th>
																<th scope="col">{t('Total')}</th>
																<th scope="col">{t('Status')}</th>
																{/* <th scope="col">{t('Actions')}</th> */}
															</tr>
														</thead>
														<tbody>
															{closedPayments.map((payment: IPayment) =>

																<tr key={payment._id}>
																	<td>
																		<div className="d-flex align-items-center">
																			<div className="flex-grow-1">
																				<p className="text-muted mb-0">
																					<Link to={'/orders/details/' + payment.order} className="text-body">
																						{payment.order}
																					</Link>
																				</p>
																			</div>
																		</div>
																	</td>
																	<td>{t(payment.paymentMethod)}</td>
																	<td>{payment.isActive ? (
																		<span className="badge bg-success-subtle text-success text-uppercase">{t('Active')}</span>
																	) : (
																		<span className="badge bg-danger-subtle text-danger text-uppercase">{t('InActive')}</span>
																	)}
																	</td>
																	<td>{payment.serviceFee + " " + t('CurrencyCode')}</td>
																	<td>{payment.total + " " + t('CurrencyCode')}</td>
																	<td>{t(payment.status)}</td>
																</tr>
															)}
														</tbody>
													</table>
												</div>
											</div> : <NoResultsComponent />
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	);
};
