import { createSlice } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { IPayment } from '../interfaces/payments.interface'
import { IPagination } from '../../../utility/interfaces/pagination.interface'
import { listSellerClosedPaymentsAction, listSellerPendingPaymentsAction, listSellerProcessingPaymentsAction, requestSellerPaymentsAction} from '../actions/payments.actions'

export const SweetAlert = withReactContent(Swal)

interface DataReducer {
  pendingPayments: Array<IPayment>
  processingPayments: Array<IPayment>
  closedPayments: Array<IPayment>
  query: IPagination
  _metadata: {
    totalPage: number
    total: number
  }
}

const initialState: DataReducer = {
  pendingPayments: [],
  processingPayments: [],
  closedPayments: [],
  query: {
    page: 1,
    perPage: 12
  },
  _metadata: {
    totalPage: 0,
    total: 0
  }
}

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listSellerPendingPaymentsAction.pending, () => {})
      .addCase(listSellerPendingPaymentsAction.fulfilled, (state, action) => {
        state.pendingPayments = action.payload.data
      })
      .addCase(listSellerPendingPaymentsAction.rejected, (_, action: any) => {
        SweetAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })
			
      .addCase(listSellerProcessingPaymentsAction.pending, () => {})
      .addCase(listSellerProcessingPaymentsAction.fulfilled, (state, action) => {
        state.processingPayments = action.payload.data
      })
      .addCase(listSellerProcessingPaymentsAction.rejected, (_, action: any) => {
        SweetAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })
			
      .addCase(listSellerClosedPaymentsAction.pending, () => {})
      .addCase(listSellerClosedPaymentsAction.fulfilled, (state, action) => {
        state.closedPayments = action.payload.data
      })
      .addCase(listSellerClosedPaymentsAction.rejected, (_, action: any) => {
        SweetAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })
			
      .addCase(requestSellerPaymentsAction.pending, () => {})
      .addCase(requestSellerPaymentsAction.fulfilled, (_, action) => {
        SweetAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })
      .addCase(requestSellerPaymentsAction.rejected, (_, action: any) => {
        SweetAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })
  }
})

export const paymentActions = paymentSlice.actions
export default paymentSlice.reducer
