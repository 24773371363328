import React from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../resources/routes-constants';
import { useProfile } from '../provider/Profile.provider';
import { ROLE } from '../utility/enums/role.enum';
import { useTranslation } from 'react-i18next';

const SidemenuComponent: React.FC<any> = () => {
	const { t } = useTranslation();
	const userProfile = useProfile()

	return (
		<div className="app-menu navbar-menu">
			<div className="navbar-brand-box">
				<NavLink to={ROUTES.HOMEPAGE} className="logo logo-light">
					<span className="logo-sm">
						<img src="/assets/images/logos/akla-beity-appicon.png" alt="" />
					</span>
					<span className="logo-lg">
						<img src="/assets/images/logos/akla-beity-logoline.png" alt="" />
					</span>
				</NavLink>
				<button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
					<i className="ri-record-circle-line"></i>
				</button>
			</div>

			<div id="scrollbar">
				<div className="container-fluid">
					<div id="two-column-menu"></div>
					<ul className="navbar-nav" id="navbar-nav">
						<li className="nav-item">
							<NavLink className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')} to={ROUTES.HOMEPAGE} role="button">
								<i className="ri-dashboard-2-line"></i> <span>{t('Dashboard')}</span>
							</NavLink>
						</li>

						{userProfile.role?.type === ROLE.ADMIN && (
							<li className="nav-item">
								<NavLink to={ROUTES.CATEGORIES_LIST} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
									<i className="ri-pencil-ruler-2-line"></i> <span>{t('Categories')}</span>
								</NavLink>
							</li>
						)}

						{userProfile.role?.type === ROLE.ADMIN && (
							<li className="nav-item">
								<NavLink to={ROUTES.ATTRIBUTES_LIST} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
									<i className="ri-honour-line"></i>
									<span>{t('Attributes')}</span>
								</NavLink>
							</li>
						)}

						<li className="nav-item">
							<NavLink to={ROUTES.PRODUCTS_LIST} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
								<i className="ri-apps-2-line"></i>
								<span>{t('Products')}</span>
							</NavLink>
						</li>

						{userProfile.role?.type === ROLE.ADMIN &&
							<li className="nav-item">
								<NavLink to={ROUTES.KITCHEN_LIST} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
									<i className="ri-rocket-line"></i>
									<span>{t('Kitchens')}</span>
								</NavLink>
							</li>
						}
						<li className="nav-item">
							<NavLink to={ROUTES.KITCHEN_VIEW} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
								<i className="ri-rocket-line"></i>
								<span>{t('MyKitchen')}</span>
							</NavLink>
						</li>

						<li className="nav-item">
							<NavLink className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')} to={ROUTES.ORDERS_LIST} role="button">
								<i className="ri-pie-chart-line"></i> <span>{t('Orders')}</span>
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')} to={ROUTES.SPECIAL_ORDERS_LIST} role="button">
								<i className="ri-stack-line"></i> <span>{t('SpecialOrders')}</span>
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')} to={ROUTES.OFFER_LIST} role="button">
								<i className="ri-file-list-3-line"></i> <span>{t('Offers')}</span>
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')} to={ROUTES.PAYMENTS_LIST} role="button">
								<i className="ri-file-list-3-line"></i> <span>{t('Payments')}</span>
							</NavLink>
						</li>
					</ul>
				</div>
			</div>

			<div className="sidebar-background"></div>
		</div>
	);
};

export default SidemenuComponent;
