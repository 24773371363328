import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';
import { IOrder } from '../interfaces/order.interface';
import { listSellerOrdersAction } from '../actions/orders.actions';
import { useLoading } from '../../../provider/IsLoading.provider';
import { Link, useNavigate } from 'react-router-dom';
import { NoResultsComponent } from '../../../components/no-results/NoResults.component';

export const OrdersListPage: React.FC<any> = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { orders, status } = useAppSelector((state) => state.order);
	const { setLoading } = useLoading();
	const navigate = useNavigate();
	const [totalEarning, setTotalEarning] = useState(0);
	const [remainingBalance, setRemainingBalance] = useState(0);
	const breadcrumb: Breadcrumb = {
		title: t('Orders'),
		links: [
			{ name: t('Dashboard'), path: '/' },
			{ name: t('Orders'), path: '/orders/list' }
		]
	};

	const navigateToDetails = (navLink: string) => {
		navigate(navLink);
	};

	useEffect(() => {
		if (status == REDUX_STATUS.SUCCEEDED || status == REDUX_STATUS.FAILED) {
			setLoading(false);
		}
		calcTotalOrders(orders);
		calcRemainingBalance(orders);
	}, [orders, setLoading, status]);

	const calcTotalOrders = (orders: IOrder[]) => {
		let total = 0;
		orders.map((order: IOrder) => {
			total += order.paymentInfo.totalPrice;
			setTotalEarning(total);
		});
	};

	const calcRemainingBalance = (orders: IOrder[]) => {
		let remaining = 0;
		orders.map((order: IOrder) => {
			remaining += order.paymentInfo.remainingAmount;
			setRemainingBalance(remaining);
		});
	};

	useEffect(() => {
		if (status == REDUX_STATUS.IDLE) {
			setLoading(true);
			dispatch(
				listSellerOrdersAction({
					page: 1,
					perPage: 12,
					orderBy: 'createdAt',
					orderDirection: SORT_DIRECTION.DESC
				})
			);
		}
	}, [dispatch, status, setLoading]);

	const orderSerachHandler = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target?.value.length > 2) {
			dispatch(
				listSellerOrdersAction({
					page: 1,
					perPage: 12,
					orderBy: 'createdAt',
					orderDirection: SORT_DIRECTION.DESC,
					search: e.target.value
				})
			);
		}
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<BreadcrumbComponent breadcrumb={breadcrumb} />
			</div>

			<div className="row">
				<div className="col-xl-3 col-md-6">
					<div className="card card-animate">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className="flex-grow-1 overflow-hidden">
									<p className="text-uppercase fw-medium text-muted text-truncate mb-0"> Total Earnings</p>
								</div>
								<div className="flex-shrink-0">
									<span className="avatar-title bg-success-subtle rounded fs-3">
										<i className="bx bx-dollar-circle text-success"></i>
									</span>
								</div>
							</div>
							<div className="d-flex align-items-end justify-content-between mt-4">
								<div>
									<h4 className="fs-22 fw-semibold ff-secondary">
										<span className="counter-value" data-target={totalEarning}>
											{totalEarning} {t('CurrencyCode')}
										</span>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6">
					<div className="card card-animate">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className="flex-grow-1 overflow-hidden">
									<p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('Orders')}</p>
								</div>
								<div className="flex-shrink-0">
									<span className="avatar-title bg-info-subtle rounded fs-3">
										<i className="bx bx-shopping-bag text-info"></i>
									</span>
								</div>
							</div>
							<div className="d-flex align-items-end justify-content-between mt-4">
								<div>
									<h4 className="fs-22 fw-semibold ff-secondary">
										<span className="counter-value" data-target={orders.length}>
											{orders.length} {t('Orders')}
										</span>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6">
					<div className="card card-animate">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className="flex-grow-1 overflow-hidden">
									<p className="text-uppercase fw-medium text-muted text-truncate mb-0">Paid Balance</p>
								</div>
								<div className="flex-shrink-0">
									<span className="avatar-title bg-warning-subtle rounded fs-3">
										<i className="bx bx-user-circle text-warning"></i>
									</span>
								</div>
							</div>
							<div className="d-flex align-items-end justify-content-between mt-4">
								<div>
									<h4 className="fs-22 fw-semibold ff-secondary">
										<span className="counter-value" data-target="183.35">
											{totalEarning - remainingBalance} {t('CurrencyCode')}
										</span>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-3 col-md-6">
					<div className="card card-animate">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className="flex-grow-1 overflow-hidden">
									<p className="text-uppercase fw-medium text-muted text-truncate mb-0"> Remaining Balance</p>
								</div>
								<div className="flex-shrink-0">
									<span className="avatar-title bg-primary-subtle rounded fs-3">
										<i className="bx bx-wallet text-primary"></i>
									</span>
								</div>
							</div>
							<div className="d-flex align-items-end justify-content-between mt-4">
								<div>
									<h4 className="fs-22 fw-semibold ff-secondary">
										<span className="counter-value" data-target={remainingBalance}>
											{remainingBalance} {t('CurrencyCode')}
										</span>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-12">
					<div>
						<div className="card">
							<div className="card-header border-0">
								<div className="row g-4">
									<div className="col-sm">
										<div className="d-flex justify-content-sm-end">
											<div className="search-box ms-2">
											<input type="text" className="form-control bg-light border-0" placeholder={t("Search_Placeholder")} onChange={(e) => orderSerachHandler(e)} />
												<i className="ri-search-line search-icon"></i>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="card-body">
								<div className="tab-content text-muted">
									{orders.length > 0 ?
										<div id="table-product-list-all" className="table-card">
											<div className="table-responsive">
												<table className="table align-middle table-nowrap mb-0">
													<thead className="table-light">
														<tr>
															<th scope="col">{t("ID")}</th>
															<th scope="col">{t('OrderType')}</th>
															<th scope="col">{t('PaymentMethod')}</th>
															<th scope="col">{t('Total')}</th>
															<th scope="col">{t('IsActive')}</th>
															<th scope="col">{t('Status')}</th>
															<th scope="col">{t('Actions')}</th>
														</tr>
													</thead>
													<tbody>
														{orders.map((order: IOrder) =>
															<tr key={order._id}>
																<td><Link to={'/orders/details/' + order._id} className="fw-medium">{order._id}</Link></td>
																<td>{order.specialOrder ? t('SpecialOrder') : t('NormalOrder')}</td>
																<td>{t(order.paymentInfo?.paymentMethod)}</td>
																<td>{order.paymentInfo.totalPrice + " " +t("CurrencyCode")}</td>
																<td>{order.isActive ? (
																	<span className="badge bg-success-subtle text-success text-uppercase">{t('Active')}</span>
																) : (
																	<span className="badge bg-danger-subtle text-danger text-uppercase">{t('InActive')}</span>
																)}
																</td>
																<td>{order.status}</td>
																<td>
																	<ul className="list-inline hstack gap-2 mb-0">
																		<li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
																			<a role="button" onClick={() => navigateToDetails('/orders/details/' + order._id)} className="text-body">
																				<i className="ri-eye-fill fs-16"></i>
																			</a>
																		</li>
																	</ul>
																</td>
															</tr>
														)}
													</tbody>
												</table>
											</div>

										</div> : <NoResultsComponent />
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
