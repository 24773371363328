import { createSlice } from '@reduxjs/toolkit'
import { IAttribute } from '../interface/attribute.interface'
import { addAttributeAction, deleteAttributeAction, getAttributeByIdAction, editAttributeAction, getAttributesAction } from '../actions/attribute.actions'
import { ErrorResponse } from '../../../utility/interfaces/ErrorResponse.interface'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'

interface DataReducer {
  attributes: IAttribute[] | null
  status: REDUX_STATUS
  error: ErrorResponse | null
}

const initialState: DataReducer = {
  attributes: null,
  status: REDUX_STATUS.IDLE,
  error: null
}

const attributeSlice = createSlice({
  name: 'attribute',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAttributesAction.pending, (state) => {
        state.error = null
        state.status = REDUX_STATUS.PENDING
      })
      .addCase(getAttributesAction.fulfilled, (state, action) => {
        state.status = REDUX_STATUS.SUCCEEDED
        state.error = null
        state.attributes = action.payload.data
      })
      .addCase(getAttributesAction.rejected, (state, action: any) => {
        state.status = REDUX_STATUS.FAILED
        state.error = action.payload
      })

      .addCase(addAttributeAction.pending, (state) => {
        state.error = null
      })
      .addCase(addAttributeAction.fulfilled, (state) => {
        state.error = null
      })
      .addCase(addAttributeAction.rejected, (state, action: any) => {
        state.error = action.payload
      })

      .addCase(deleteAttributeAction.pending, (state) => {
        state.error = null
      })
      .addCase(deleteAttributeAction.fulfilled, (state) => {
        state.error = null
      })
      .addCase(deleteAttributeAction.rejected, (state, action: any) => {
        state.error = action.payload
      })

      .addCase(getAttributeByIdAction.pending, (state) => {
        state.error = null
      })
      .addCase(getAttributeByIdAction.fulfilled, (state) => {
        state.error = null
      })
      .addCase(getAttributeByIdAction.rejected, (state, action: any) => {
        state.error = action.payload
      })

      .addCase(editAttributeAction.pending, (state) => {
        state.error = null
      })
      .addCase(editAttributeAction.fulfilled, (state) => {
        state.error = null
      })
      .addCase(editAttributeAction.rejected, (state, action: any) => {
        state.error = action.payload
      })
  }
})

export const attributeActions = attributeSlice.actions
export default attributeSlice.reducer
