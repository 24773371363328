import React from "react";
import { useTranslation } from "react-i18next";
interface WarningComponentProps {
	title: string;
	text: string;
	actionHandler: () => void;
}

const WarningComponent: React.FC<WarningComponentProps> = ({ title, text, actionHandler }) => {
	const { t } = useTranslation();
	return (
		<div id="warningModal" className="modal fade zoomIn" tabIndex={-1}>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"></button>
					</div>
					<div className="modal-body">
						<div className="text-center">
							<div className="fs-15 mx-4 mx-sm-5">
								<h4>{title}</h4>
								<p className="text-muted mx-4 mb-0">{text}</p>
							</div>
						</div>
						<div className="d-flex gap-2 justify-content-center mt-4 mb-2">
							<button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal">
								{t("No")}
							</button>
							<button type="button" className="btn w-sm btn-danger" id="delete-product" onClick={actionHandler} data-bs-dismiss="modal">
								{t("Yes")}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WarningComponent;
