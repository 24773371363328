import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getProfileAction, updatePasswordAction, updateProfileAction, uploadImageAction } from "../action/profile.action";
import { ErrorResponse } from "react-router-dom";
import { IUser } from "../interface/User.interface";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export const SuccessAlert = withReactContent(Swal)

interface DataReducer {
	contents: IUser,
  status: string,
  error: ErrorResponse | null;
}

const initialState: DataReducer = {
  contents: {} as IUser,
  status: 'idle',
  error: null
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
		setProfile(state: DataReducer, action: PayloadAction<any>) {
			state.contents = action.payload;
		}
  },
  extraReducers: (builder) => {
    builder
    .addCase(uploadImageAction.pending, (state) => {
      state.status = 'pending';
    })
    .addCase(uploadImageAction.fulfilled, (state, action) => {
      state.contents = action.payload.data;

    })
    .addCase(uploadImageAction.rejected, (state, action: any) => {
      state.status = 'faild';
      state.error = action.payload
    })

    .addCase(getProfileAction.pending, (state) => {
      state.status = 'pending';
    })
    .addCase(getProfileAction.fulfilled, (state, action) => {
      state.contents = action.payload.data;
    })
    .addCase(getProfileAction.rejected, (state, action: any) => {
      state.status = 'faild';
      state.error = action.payload
    })

    .addCase(updateProfileAction.pending, (state) => {
      state.status = 'pending';
    })
    .addCase(updateProfileAction.fulfilled, (state, action) => {
      SuccessAlert.fire({ position: 'center', icon: 'success', title: 'Profile Updated successfully', showConfirmButton: !0, timer: 5000 })
      state.contents = action.payload.data;

    })
    .addCase(updateProfileAction.rejected, (state, action: any) => {
      state.status = 'faild';
      state.error = action.payload
    })

    .addCase(updatePasswordAction.pending, (state) => {
      state.status = 'pending';
    })
    .addCase(updatePasswordAction.fulfilled, () => {
      // state.contents = action.payload.data;
    })
    .addCase(updatePasswordAction.rejected, (state, action: any) => {
      state.status = 'faild';
      state.error = action.payload
    })
  }
});

export const profileActions = profileSlice.actions;
export default profileSlice.reducer;
