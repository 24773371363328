import React, { useEffect, useState } from 'react';
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { getAdminCategoriesAction } from '../action/Category.action';
import { ROUTES } from '../../../resources/routes-constants';
import { Link, useNavigate } from 'react-router-dom';
import ViewModalComponent from '../../../components/item-view-modal/View-modal.component';
import { ICategory } from '../interface/Category.interface';
import { useTranslation } from 'react-i18next';
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';
import { useLoading } from '../../../provider/IsLoading.provider';
import { NoResultsComponent } from '../../../components/no-results/NoResults.component';
import { Translation } from '../../../utility/helpers/translation.helper';

const CategoriesListPage: React.FC<any> = () => {
	const assets = process.env.REACT_APP_ASSETS_URL;
	const { t } = useTranslation();
	const { setLoading } = useLoading();
	const breadcrumb: Breadcrumb = {
		title: t('Categories'),
		links: [
			{ name: t('Dashboard'), path: '/' },
			{ name: t('Categories'), path: '/categories/list' }
		]
	};
	const dispatch = useAppDispatch();
	const { categories, status } = useAppSelector((state) => state.category);
	const [data, setData] = useState([]);
	const navigate = useNavigate();
	const [viewItem, setViewItem] = useState(<></>);

	useEffect(() => {
		if (status == REDUX_STATUS.IDLE) {
			setLoading(true);
			dispatch(
				getAdminCategoriesAction({
					offset: 1,
					limit: 12,
					order: {
						orderBy: 'createdAt',
						orderDirection: SORT_DIRECTION.ASC
					}
				})
			);
		} else {
			setData(categories as never);
		}
	}, [dispatch, categories, status, setLoading]);

	useEffect(() => {
		if (status == REDUX_STATUS.SUCCEEDED || status == REDUX_STATUS.FAILED) {
			setLoading(false);
		}
	}, [setLoading, status]);

	const modalBodyHandler = (category: ICategory) => {
		setViewItem(
			<div className="row">
				<div className="col-xl-12">
					<div className="card">
						<div className="card-body">
							<div className="table-responsive table-card">
								<table className="table table-nowrap align-middle table-borderless mb-0">
									<thead className="table-light text-muted">
										<tr>
											<th scope="col">{t('ID')}</th>
											<th scope="col">{t('Details')}</th>
											<th scope="col">{t('Status')}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<p className="text-muted mb-0">{category._id}</p>
											</td>
											<td>
												<div className="d-flex">
													<div className="flex-shrink-0 avatar-md bg-light rounded p-1">
														<img src={assets + category.icon} alt="" className="img-fluid d-block" />
													</div>
													<div className="flex-grow-1 ms-3">
														<h5 className="fs-15">{category.name.En}</h5>
														<p className="text-muted mb-0">{category.name.Ar}</p>
													</div>
												</div>
											</td>
											<td>
												{category.isActive ? (
													<span className="badge bg-success-subtle text-success text-uppercase">{t('Active')}</span>
												) : (
													<span className="badge bg-danger-subtle text-danger text-uppercase">{t('InActive')}</span>
												)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-12">
					<div className="card">
						<div className="card-header">
							<h5 className="card-title mb-0">
								<i className="ri-secure-payment-line align-bottom me-1 text-muted"></i> {t('MetaData')}
							</h5>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const navigateTo = (e: string, type: string) => {
		navigate('/categories/' + type + '/' + e);
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<BreadcrumbComponent breadcrumb={breadcrumb} />
			</div>

			<div className="row">
				<div className="col-lg-12">
					<div>
						<div className="card">
							<div className="card-header border-0">
								<div className="row g-4">
									<div className="col-sm-auto">
										<div>
											<Link to={ROUTES.CATEGORIES_CREATE} className="btn btn-primary" id="addproduct-btn">
												<i className="ri-add-line align-bottom me-1"></i> {t("Add")}
											</Link>
										</div>
									</div>
									<div className="col-sm">
										<div className="d-flex justify-content-sm-end">
											<div className="search-box ms-2">
												<input type="text" className="form-control" id="searchProductList" placeholder={t("Search_Placeholder")} />
												<i className="ri-search-line search-icon"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
							{categories.length > 0 &&
								<div className="card-body">
									<div id="table-product-list-all" className="table-card">
										<div className="table-responsive">
											<table className="table align-middle table-nowrap mb-0">
												<thead className="table-light">
													<tr>
														<th scope="col">{t("ID")}</th>
														<th scope="col">{t('Name')}</th>
														<th scope="col">{t('IsActive')}</th>
														<th scope="col">{t('Actions')}</th>
													</tr>
												</thead>
												<tbody>
													{categories.map((category: ICategory) =>

														<tr key={category._id}>
															<td>
																<a role="button" className="text-body" data-bs-toggle="modal" data-bs-target="#viewModal" onClick={() => modalBodyHandler(category)}>
																	{category._id}
																</a>
															</td>
															<td>
																<div className="d-flex align-items-center">
																	<div className="flex-shrink-0 me-3">
																		<div className="avatar-sm bg-light rounded p-1">
																			<img src={assets + category.icon} alt="" className="img-fluid d-block" />
																		</div>
																	</div>
																	<div className="flex-grow-1">
																		<h5 className="fs-14 mb-1">
																			<a role="button" className="text-body" data-bs-toggle="modal" data-bs-target="#viewModal" onClick={() => modalBodyHandler(category)}>
																				{Translation(category.name)}
																			</a>
																		</h5>
																	</div>
																</div>
															</td>
															<td>{category.isActive
																? '<span class="badge bg-success-subtle text-success text-uppercase">Active</span>'
																: '<span class="badge bg-danger-subtle text-danger text-uppercase">Inactive</span>'}</td>
															<td>
																<ul className="list-inline hstack gap-2 mb-0">
																	<li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
																		<a role="button" className="text-primary d-inline-block" data-bs-toggle="modal" data-bs-target="#viewModal" onClick={() => modalBodyHandler(category)}>
																			<i className="ri-eye-fill fs-16"></i>
																		</a>
																	</li>
																	<li className="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
																		<a role="button" className="text-primary d-inline-block edit-item-btn" onClick={() => navigateTo(category._id, 'edit')}>
																			<i className="ri-pencil-fill fs-16"></i>
																		</a>
																	</li>
																</ul>
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							}
							{!categories.length && <NoResultsComponent />}
						</div>
					</div>
				</div>
			</div>
			<ViewModalComponent body={viewItem} />
		</div>
	);
};

export default CategoriesListPage;
